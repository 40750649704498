import { useState, useContext, useEffect } from 'react'
import {
	calculateDuration,
	capitalizeFirstLetter,
	dataTableColumnSort,
	convertUTCtoLocalByOffset,
	getStaffId
} from '../../helpers/utils'
import { Column, ColumnSortEvent } from 'primereact/column'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { DataTable } from 'primereact/datatable'
import {
	initialValuesForConfirmPopup,
	timeOffChangeRequest,
	timeOffActionTemplate,
	timeOffDelete,
	getFullDataById,
	initialValuesForTimeOff,
	timeOffActionTemplateHistory,
	getAllUserTimeOffRequests,
	getAllByUser,
} from '../../helpers/timeOffHelper'
import { PageMode, AlertVariant, MOBILE_VIEW_BREAKPOINT } from '../../constants/constants'
import { parseTimeForTimePicker } from '../../helpers/newsAndEventsHelper'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import {
	TimeOffEntry,
	ConfirmPopupType,
	TimeOffRequestStatus,
	ToastMessageProps,
	TimeOffStatuses,
	AddTimeOffResponse,
	Id,
} from '../../constants/staticTypes'
import { useLocation } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { USERS_TIME_OFF_LIST } from '../../constants/queryKeys'
import CommonModal from '../common_modal/CommonModal'
import dayjs from 'dayjs'
import useMutationHook from '../../hooks/useMutationHook'
import {
	CANCEL_TIME_OFF_REQUEST,
	CANCEL_TIME_OFF,
	DELETE_TIME_OFF_REQUEST,
	DELETE_TIME_OFF,
	DURATION,
	NAME,
	NOTE,
	REASON,
	REJECT_TIME_OFF,
	REJECTING_TIME_OFF_REQUEST,
	SUBMIT_DATE,
	FROM_DATE,
	TO_DATE,
	REQUEST_DATE,
	DELETE,
	EDIT
} from '../../constants/strings'
import { routes } from '../../constants/routes'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useWindowSize } from 'react-use'
import { DataView } from 'primereact/dataview'
import { Card } from 'primereact/card'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { useTranslation } from '../../hooks/useTranslation'

type Props = {
	tableData: TimeOffEntry[]
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
	setMode: React.Dispatch<React.SetStateAction<PageMode>>
	showSideBar: boolean
	setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
}

const UserTimeOffRequestsDataGrid = ({ tableData, setToastMessage, setMode, setShowSideBar, showSideBar }: Props) => {
	const queryClient = useQueryClient()
	const timeOffMutation = useMutationHook(queryClient, true)
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext
	const location = useLocation()
	const currentPath = location.pathname
	const staffId = getStaffId()
	const { setTimeOffData } = useContext(ScheduleManagementCtx)
	const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

	const [rejectReason, setRejectReason] = useState('')
	const [requestInfo, setRequestInfo] = useState<ConfirmPopupType>(initialValuesForConfirmPopup)
	const [showWarning, setShowWarning] = useState(false)

	const [translationText, setTranslatedText] = useState<{
		edit: string,
		delete: string,
		search: string,
		paginationTemplate: string,
		emptyMessage: string,
	  }>({ 
		edit: EDIT,
		delete: DELETE,
		search: 'Search',
		paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
		emptyMessage: "No Requests Found"
	});

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: EDIT, typeCase: 'pascal', translationContext }),
					jTranslationText({ text: DELETE, typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'Showing 1 to 2 of 3 items', typeCase: 'pascal', translationContext }),
					jTranslationText({ text: 'No Requests Found', typeCase: 'pascal', translationContext }),
				])
				
				setTranslatedText({
					edit: translations[0] ?? EDIT,
					delete: translations[1] ?? DELETE,
					search: translations[2] ?? 'Search',
					paginationTemplate:
						translations[3]
							?.replace('1', '{first}')
							?.replace('2', '{last}')
							?.replace('3', '{totalRecords}') ?? 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: translations[4] ?? "No Requests Found"
				})
			} catch {
				setTranslatedText({
					edit: EDIT,
					delete: DELETE,
					search: 'Search',
					paginationTemplate: 'Showing {first} to {last} of {totalRecords} items',
					emptyMessage: "No Requests Found"
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	useEffect(() => {
		if (!showSideBar) {
			setRequestInfo(initialValuesForConfirmPopup)
			setTimeOffData(initialValuesForTimeOff)
			setMode(PageMode.ADD)
		}
	}, [showSideBar]) // eslint-disable-line

	useEffect(() => {
		if (requestInfo.action === 'EDITED') getFullRequestDataById()
	}, [requestInfo]) // eslint-disable-line

	const approveAction = () => {
		switch (requestInfo.action) {
			case 'CANCELLED':
				changeRequestStatus('CANCELLED')
				break
			case 'REJECTED':
				changeRequestStatus('REJECTED')
				break
			case 'DELETE':
				const params: Id = { id: requestInfo.requestId }
				closeRejectionModal();
				timeOffDelete(timeOffMutation, params, onSuccess, onError)
				break
			default:
				changeRequestStatus('REJECTED')
		}
	}

	const getTitle = () => {
		switch (requestInfo.action) {
			case 'CANCELLED':
				return CANCEL_TIME_OFF
			case 'REJECTED':
				return REJECT_TIME_OFF
			case 'DELETE':
				return DELETE_TIME_OFF
			default:
				return CANCEL_TIME_OFF
		}
	}

	const getModelText = () => {
		switch (requestInfo.action) {
			case 'CANCELLED':
				return CANCEL_TIME_OFF_REQUEST
			case 'REJECTED':
				return REJECTING_TIME_OFF_REQUEST
			case 'DELETE':
				return DELETE_TIME_OFF_REQUEST

			default:
				return CANCEL_TIME_OFF
		}
	}

	const userName = (timeOff: TimeOffEntry) => {
		const { userData } = timeOff
		return <span>{userData?.preferredName ?? userData?.firstName}</span>
	}

	const parseDuration = (timeOff: TimeOffEntry) => {
		const { type, startDate, startTime, endTime, endDate } = timeOff
		// Get today's date
		const today = dayjs()

		// Parse the start and end times
		const startTimeParsed = dayjs(`2000-01-01 ${startTime}`, { format: 'YYYY-MM-DD HH:mm:ss' })
		const endTimeParsed = dayjs(`2000-01-01 ${endTime}`, { format: 'YYYY-MM-DD HH:mm:ss' })

		// Combine today's date with the start time
		const start = today
			.set('hour', startTimeParsed.hour())
			.set('minute', startTimeParsed.minute())
			.set('second', startTimeParsed.second())

		// Combine today's date with the end time
		const end = today
			.set('hour', endTimeParsed.hour())
			.set('minute', endTimeParsed.minute())
			.set('second', endTimeParsed.second())

		return calculateDuration({
			startTime: dayjs(start),
			endTime: dayjs(end),
			fromDate: startDate,
			toDate: endDate,
			type: type,
		})
	}

	const durationTemplate = (timeOff: TimeOffEntry) => {
		const duration = parseDuration(timeOff)
		if (timeOff.type === 'time-off') {
			return (
				<>
					{' '}
					<div className="d-flex">
						<div className="flex-grow-0">
							<span>{duration}</span>
						</div>
						<div className="flex-grow-0">
							<OverlayTrigger
								trigger={["hover", "hover"]}
								placement="auto"
								overlay={
									<Tooltip className="addon-tooltip">
										{dayjs(`${timeOff.startDate} ${timeOff.startTime}`)
											.format('hh:mm A')
											.toString()}{' '}
										- {dayjs(`${timeOff.endDate} ${timeOff.endTime}`).format('hh:mm A').toString()}
									</Tooltip>
								}
							>
								<span className="table-help-main ms-1">
									<i className="ri-time-line"></i>
								</span>
							</OverlayTrigger>
						</div>
					</div>
				</>
			)
		}
		return <span>{duration}</span>
	}

	const rejectRequestModalContent = (
		<>
			<div><JTranslation typeCase="capitalize" text={getModelText()} /></div>
			{requestInfo.action !== 'DELETE' && (
				<>
					<p className="mt-3 mb-0">
						<JTranslation typeCase="pascal" text={NOTE} />
						<span className="mandatory ">*</span>
					</p>
					<textarea
						name="reject-reason"
						className="form-control reason"
						value={rejectReason}
						onChange={(e) => setRejectReason(e.target.value)}
					/>
				</>
			)}
		</>
	)

	const closeRejectionModal = () => {
		setShowWarning(false)
		setRejectReason('')
	}

	const onSuccess = (res: AddTimeOffResponse) => {
		setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
		if (currentPath === routes.time_off_requests) {
			queryClient.fetchQuery(USERS_TIME_OFF_LIST, () => getAllUserTimeOffRequests({ limit: 500 }))
		} else {
			queryClient.fetchQuery(USERS_TIME_OFF_LIST, () => getAllByUser({ id: staffId, limit: 500 }))
		}
	}

	const onFullDataSuccess = (res: TimeOffEntry) => {
		const { endTime, notes, reason, startTime, type, endDate, startDate, userData } = res

		const parsedStartTime = parseTimeForTimePicker({ formattedTime: startTime, format: 'HH:mm' })
		const parsedEndTime = parseTimeForTimePicker({ formattedTime: endTime, format: 'HH:mm' })

		// set data to timeOff request form
		setTimeOffData({
			startTime: parsedStartTime!,
			endTime: parsedEndTime!,
			fromDate: new Date(startDate),
			toDate: new Date(endDate),
			duration: calculateDuration({
				type: type,
				startTime: parsedStartTime!,
				endTime: parsedEndTime!,
				fromDate: startDate,
				toDate: endDate,
			}),
			note: notes,
			reason,
			type,
			staffId: userData?.preferredName === 'Super Admin' ? 'superadmin' : res?.staffId as string,
			id: requestInfo.requestId,
		})
		// open request TimeOff sidebar
		setShowSideBar(true)
	}

	const onError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}

	const changeRequestStatus = (status: TimeOffStatuses) => {
		const params: TimeOffRequestStatus = { id: requestInfo.requestId, newStatus: status, note: rejectReason }
		// approve api call
		closeRejectionModal();
		timeOffChangeRequest(timeOffMutation, params, onSuccess, onError)
	}

	const getFullRequestDataById = () => {
		const params: Id = { id: requestInfo.requestId }
		getFullDataById(timeOffMutation, params, onFullDataSuccess, onError)
	}

	const sortDuration = (params: ColumnSortEvent) => {
		const data = params.data as any[]
		const order = params.order ?? 1
		const parsedDurationData = data?.slice().map((timeOff) => {
			timeOff.duration = parseDuration(timeOff)
			return timeOff
		})
		return dataTableColumnSort(parsedDurationData, 'duration', order)
	}

	const requestDateTemplate = (timeOff: TimeOffEntry) => {
		const type = timeOff.type
		const startDate = convertUTCtoLocalByOffset(timeOff.startDate)
		const endDate = convertUTCtoLocalByOffset(timeOff.endDate)
		if (type === 'full-day' && startDate !== endDate) {
			return (
				<span>
					{startDate} - {endDate}{' '}
				</span>
			)
		}
		return <span>{startDate}</span>
	}

	const overlayTemplate = (data: string = '') => {
		if (data?.length > 20) {
			return (
				<OverlayTrigger
					trigger={["hover", "hover"]}
					placement="auto"
					overlay={<Tooltip className="addon-tooltip">{data}</Tooltip>}
				>
					<span>{data.slice(0, 20) + '...'}</span>
				</OverlayTrigger>
			)
		}
		return <span>{data}</span>
	}

	const moreInfoTemplate = (rowData: any) => {
		let noteElement = null
		if (['REJECTED', 'CANCELLED'].includes(rowData.actionStatus)) {
			noteElement = (
				<div className="popover-time-off-more-info">
					<p className="more-info">
						<JTranslation typeCase="pascal" text={rowData.actionStatus} /> on {dayjs(rowData.updatedAt).format('MM/DD/YYYY')} at{' '}
						{dayjs(rowData.updatedAt).format('hh:mm a')}
					</p>
					{rowData.note && (
						<div className="notes">
							<span>
								<b><JTranslation typeCase="pascal" text={"Notes"} /></b>
							</span>
							<br />
							<span>{rowData.note}</span>
						</div>
					)}
				</div>
			)
		}
		return noteElement
	}

	const itemTemplate = (data: TimeOffEntry) => {
		return (
			<Card className="data-view-card mt-2 d-flex flex-column justify-content-even">
				<div className="view-header d-flex flex-row justify-content-between align-items-center">
					<p className="m-0">{userName(data)}</p>
					<p className="m-0">{durationTemplate(data)}</p>
				</div>
				<div className='mt-2'>
					{data?.reason && (
						<p className="m-0">
							<JTranslation typeCase="pascal" text={"Reason"} />: <span className="font-bold">{capitalizeFirstLetter(data.reason)}</span>
						</p>
					)}
					<p className="m-0">
						<JTranslation typeCase="pascal" text={"Request Date"} />: <span className="font-bold">{requestDateTemplate(data)}</span>
					</p>
					<p className="m-0">
						<JTranslation typeCase="pascal" text={"Submitted Date"} />: <span className="font-bold">{convertUTCtoLocalByOffset(data.submittedDate)}</span>
					</p>
				</div>
				<div className="view-footer mt-2 pt-3 d-flex flex-row justify-content-end align-items-center">
					{currentPath === routes.time_off_requests
						? timeOffActionTemplate({
								queryClient: queryClient,
								timeOff: data,
								setMode: setMode,
								setRequestInfo: setRequestInfo,
								setShowWarning: setShowWarning,
								note: moreInfoTemplate(data),
								isMobileView: true,
								translateEdit: translationText.edit,
								translateDelete: translationText.delete,
						  })
						: timeOffActionTemplateHistory({
								queryClient: queryClient,
								timeOff: data,
								setMode: setMode,
								setRequestInfo: setRequestInfo,
								setShowWarning: setShowWarning,
								note: moreInfoTemplate(data),
								isMobileView: true,
								translateEdit: translationText.edit,
								translateDelete: translationText.delete,
						  })}
				</div>
			</Card>
		)
	}

	return (
		<div className="row mb-5 h-100">
			{/* accept request popup */}
			<ConfirmPopup
				target={document.getElementById(requestInfo.buttonName) ?? undefined}
				visible={requestInfo.visible}
				onHide={() =>
					setRequestInfo((prev) => {
						return {
							...prev,
							visible: false,
						}
					})
				}
				message={useTranslation("Are you sure you want to proceed?", "capitalize")}
				icon="pi pi-exclamation-triangle"
				accept={() => changeRequestStatus('APPROVED')}
			/>

			{/* reject request popup */}
			<CommonModal
				show={showWarning}
				modalContent={rejectRequestModalContent}
				title={getTitle()}
				modalSize="md"
				disableProceed={!rejectReason && requestInfo.action !== 'DELETE'}
				onHide={closeRejectionModal}
				callback={approveAction}
				hideCloseButton
			/>

			<div className="col-lg-12" style={{ height: '100%', overflow: 'auto' }}>
				{width <= breakPoint ? (
					<DataView
						value={tableData}
						layout={'list'}
						itemTemplate={itemTemplate}
						paginator
						rows={10}
                        paginatorClassName="p-dataview-timeoff"
						// paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
						// rowsPerPageOptions={[10, 25, 50]}
						dataKey="id"
						emptyMessage={translationText.emptyMessage}
						// currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
					/>
				) : (
					<DataTable
						value={tableData}
						paginator
						className="p-datatable-customers"
						rows={10}
						scrollable
						scrollHeight="flex"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowsPerPageOptions={[10, 25, 50]}
						dataKey="id"
						rowHover
						responsiveLayout="scroll"
						emptyMessage={translationText.emptyMessage}
						currentPageReportTemplate={translationText.paginationTemplate}
					>
						{currentPath === routes.time_off_requests && (
							<Column
								field="userData"
								style={{ minWidth: '15rem' }}
								header={<JTranslation typeCase="pascal" text={NAME} />}
								className="word-break-grid"
								body={userName}
							/>
						)}
						<Column
							field="reason"
							header={<JTranslation typeCase="pascal" text={REASON} />}
							className="word-break-grid"
							style={{ minWidth: `${currentPath === routes.time_off ? '15rem' : '13rem'}` }}
							body={(post) => overlayTemplate(post.reason)}
						/>
						<Column
							field="submittedDate"
							header={<JTranslation typeCase="pascal" text={SUBMIT_DATE} />}
							style={{ minWidth: '10rem' }}
							sortable
							body={(post) => convertUTCtoLocalByOffset(post.submittedDate)}
						/>

						{currentPath === routes.time_off_requests && (
							<Column
								field="startDate"
								header={<JTranslation typeCase="pascal" text={REQUEST_DATE} />}
								style={{ minWidth: '15rem' }}
								sortable
								body={requestDateTemplate}
							/>
						)}

						{currentPath === routes.time_off && (
							<Column
								field="startDate"
								header={<JTranslation typeCase="pascal" text={FROM_DATE} />}
								sortable
								style={{ minWidth: '12rem' }}
								body={(post) => convertUTCtoLocalByOffset(post.startDate)}
							/>
						)}
						{currentPath === routes.time_off && (
							<Column
								field="endDate"
								header={<JTranslation typeCase="pascal" text={TO_DATE} />}
								style={{ minWidth: '12rem' }}
								sortable
								body={(post) => convertUTCtoLocalByOffset(post.endDate)}
							/>
						)}

						<Column
							field="publishedStatus"
							header={<JTranslation typeCase="pascal" text={DURATION} />}
							sortable
							sortFunction={sortDuration}
							body={durationTemplate}
							style={{ maxWidth: '8rem', minWidth: '8rem' }}
						/>
						{/* <Column field="actionStatus" header="Status" sortable /> */}
						<Column
							style={{ minWidth: `${currentPath === routes.time_off ? '14rem' : '18rem'}` }}
							bodyClassName="bg-sticky justify-content-start"
							alignFrozen="right"
							body={(rowData) => {
								if (currentPath === routes.time_off_requests) {
									return timeOffActionTemplate({
										queryClient: queryClient,
										timeOff: rowData,
										setMode: setMode,
										setRequestInfo: setRequestInfo,
										setShowWarning: setShowWarning,
										note: moreInfoTemplate(rowData),
										translateEdit: translationText.edit,
										translateDelete: translationText.delete,
									})
								} else {
									return timeOffActionTemplateHistory({
										queryClient: queryClient,
										timeOff: rowData,
										setMode: setMode,
										setRequestInfo: setRequestInfo,
										setShowWarning: setShowWarning,
										note: moreInfoTemplate(rowData),
										translateEdit: translationText.edit,
										translateDelete: translationText.delete,
									})
								}
							}}
							frozen={true}
						/>
					</DataTable>
				)}
			</div>
		</div>
	)
}

export default UserTimeOffRequestsDataGrid
