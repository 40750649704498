import { useContext, useEffect, useState } from 'react'
import { AreaType, JobRole, JobRoleAddForm, JobRoleEditForm, ToastMessageProps } from '../../constants/staticTypes'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { useQueryClient } from 'react-query'
import useMutationHook from '../../hooks/useMutationHook'
import { FormClass, toastMessageInitialData } from '../../constants/constants'
import { ROLES_LIST } from '../../constants/queryKeys'
import { createNewRole, updateRole } from '../../helpers/roleHelper'
import Offcanvas from 'react-bootstrap/esm/Offcanvas'
import ToastAlert from '../alert/ToastAlert'
import { JTranslation } from '../../helpers/jTranslate'
import { AREA_OF_OPERATION, CANCEL, ROLE_DESC, ROLE_NAME, SAVE, UPDATE } from '../../constants/strings'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'
import ColorPalette from '../color_picker/ColorPicker'

type Props = {
	editRole: JobRole | undefined
	areaList: AreaType[]
	setEditRoleData: React.Dispatch<React.SetStateAction<JobRole | undefined>>
	handleClose: () => void
}

const AddOnsSideBar = ({ editRole, areaList, setEditRoleData, handleClose }: Readonly<Props>) => {
	// CONTEXT VARIABLE
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)
	// LOCAL VARIABLES
	const queryClient = useQueryClient()
	const addOnMutation = useMutationHook(queryClient, true) // add or update role mutation
	// STATE VARIABLES
	const [roleColorHex, setRoleColorHex] = useState('')
	const [formData, setFormData] = useState<JobRoleAddForm | JobRoleEditForm>({
		jobRoleName: '',
		jobRoleDescription: '',
		jobRoleDepartmentId: '',
		isAvailableForSchedule: true,
		styleDetails: {
			backgroundColor: "",
			color: null
		}
	})
	// ALERT VARIABLES
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	// effect for setting the form data
	useEffect(() => {
		if (editRole) {
			const backgroundColor = roleColorHex ? roleColorHex : editRole?.styleDetails.backgroundColor

			setFormData({
				jobRoleDescription: editRole?.jobRoleDescription !== null ? editRole?.jobRoleDescription : '',
				jobRoleName: editRole?.jobRoleName !== null ? editRole?.jobRoleName : '',
				id: editRole?.id !== null ? editRole?.id : '',
				jobRoleDepartmentId: editRole?.jobRoleDepartmentId ?? '',
				isAvailableForSchedule: !!editRole?.isAvailableForSchedule,
				styleDetails: {
					backgroundColor: backgroundColor,
					color: null
				}
			})
			setRoleColorHex(backgroundColor)
		}
	}, [editRole, roleColorHex])

	// on add or update addOn success
	const onSuccess = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
		queryClient.refetchQueries([ROLES_LIST]) // nosonar
		cleanUpData()
	}

	// on add or update addOn error
	const onError = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
	}

	// clear data
	const cleanUpData = () => {
		setFormData({
			jobRoleName: "",
			jobRoleDescription: "",
			jobRoleDepartmentId: "",
			isAvailableForSchedule: true,
			styleDetails: { backgroundColor: '', color: null },
		});
		setEditRoleData(undefined)
		setToastMessage(toastMessageInitialData)
		setRoleColorHex("")
		setShowSideBar(false)
	}

	const isFormValid = () => {
		let jobRoleName = formData.jobRoleName?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID
		let jobRoleDescription = formData.jobRoleDescription?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID
		let jobRoleDepartmentId = formData.jobRoleDepartmentId?.trim().length > 0 ? FormClass.VALID : FormClass.INVALID

		if (jobRoleName === FormClass.VALID && jobRoleDescription === FormClass.VALID && jobRoleDepartmentId === FormClass.VALID) {
			return true
		} else {
			return false
		}
	}

	const submitForm = () => {
		if (editRole) {
			// update addon api call
			updateRole(addOnMutation, formData as JobRoleEditForm, onSuccess, onError)
		} else {
			// create addon api call
			createNewRole(addOnMutation, formData as JobRoleAddForm, onSuccess, onError)
		}
	}

	return (
		<Offcanvas
			show={showSideBar}
			className="custom-offcanvas"
			onHide={() => {
				handleClose()
				cleanUpData()
			}}
			backdrop="static"
			// responsive="xl"
			placement="end"
		>
			{/* TOAST MESSAGE COMPONENT */}
			<ToastAlert
				show={toastMessage.show}
				onClose={() => setToastMessage(toastMessageInitialData)}
				message={toastMessage.message}
				variant={toastMessage.variant}
			/>

			<Offcanvas.Header closeButton>
				<Offcanvas.Title>
					{editRole ? (
						<JTranslation typeCase="pascal" text={'Edit Role'} />
					) : (
						<JTranslation typeCase="pascal" text={'Add New Role'} />
					)}
				</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className="row">
					<div className=" col-md-12 col-lg-12 mb-3">
						<label htmlFor="validationCustom01" className="form-label">
							<JTranslation typeCase="pascal" text={ROLE_NAME} />
							<span className="mandatory ">*</span>{' '}
						</label>
						<input
							type="text"
							className="form-control"
							id="validationCustom01"
							autoComplete="off"
							maxLength={100}
							value={formData.jobRoleName}
							data-testid="role-name-input"
							onChange={(e) => {
								setFormData({ ...formData, jobRoleName: e.target.value })
							}}
						/>
					</div>
					<div className=" col-md-12 col-lg-12 mb-3">
						<ColorPalette
							onColorChange={(hexCode) => setRoleColorHex(hexCode)}
							colorHexFromParent={roleColorHex}
						/>
					</div>
					<div className=" col-md-12 col-lg-12 mb-3 d-flex flex-column">
						<label htmlFor="area" className="form-label">
							<JTranslation typeCase="pascal" text={AREA_OF_OPERATION} />
							<span className="mandatory ">*</span>{' '}
						</label>
						<Dropdown
							className={' w-100'}
							data-testid='area'
							value={formData.jobRoleDepartmentId}
							options={areaList}
							optionLabel="departmentName"
							optionValue='id'
							onChange={(e) => {
								setFormData({
									...formData,
									jobRoleDepartmentId: e.value,
								})
							}}
						/>
					</div>
					<div className=" col-md-12 col-lg-12 mb-3">
						<label htmlFor="validationCustom01" className="form-label">
							<JTranslation typeCase="pascal" text={ROLE_DESC} />
							<span className="mandatory ">*</span>{' '}
						</label>
						<div className="input-group custom-prepend-input">
							<textarea
								className="form-control"
								rows={3}
								data-testid="role-desc"
								autoComplete="off"
								value={formData.jobRoleDescription}
								onChange={(e) => setFormData({ ...formData, jobRoleDescription: e.target.value })}
								maxLength={2000}
							></textarea>
						</div>
					</div>
					<div className=" col-md-12 col-lg-12 mb-3 mt-2">
						<div className='d-flex flex-row align-items-center'>
							<Checkbox
								inputId="isAvailableForSchedule"
								name="isAvailableForSchedule"
								value={!!formData.isAvailableForSchedule}
								onChange={(e) => setFormData({ ...formData, isAvailableForSchedule: !!e.target.checked })}
								checked={!!formData.isAvailableForSchedule}
							/>
							<label htmlFor="isAvailableForSchedule" className="ms-2 user-select-none">
								<JTranslation typeCase="pascal" text={'Available For Schedule'} />
							</label>
						</div>
					</div>
				</div>

				<div className="save-btn-section shadow save-btn-absolute">
					<button
						className="btn btn-custom-primary-outline"
						type="button"
						data-testid="cancel-btn"
						onClick={() => {
							handleClose()
							cleanUpData()
						}}
					>
						<JTranslation typeCase="pascal" text={CANCEL} />
					</button>

					<button
						className="btn btn-custom-primary"
						type="button"
						disabled={!isFormValid()}
						data-testid="save-btn"
						onClick={() => {
							// validate and submit form
							if (isFormValid()) {
								submitForm()
							}
						}}
					>
						{editRole ? (
							<JTranslation typeCase="pascal" text={UPDATE} />
						) : (
							<JTranslation typeCase="pascal" text={SAVE} />
						)}
					</button>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default AddOnsSideBar
