import { useContext, useEffect, useState } from 'react'
import { DATE_FORMAT, PageMode } from '../../constants/constants'
import { AvailabilityFormData, ToastMessageProps } from '../../constants/staticTypes'
import { REGULAR_AVAILABILITY, SET_AVAILABILITY, TEMP_AVAILABILITY } from '../../constants/strings'
import { CommonCtx } from '../../context/CommonCtxProvider'
import AvailabilityForm from './AvailabilityForm'
import { JTranslation } from '../../helpers/jTranslate'
import { Button, Drawer } from 'antd'
import { CloseOutlined } from "@ant-design/icons"
import dayjs from 'dayjs'
import SchedulerAvailabilityForm from '../manage_work_schedule/SchedulerAvailabilityForm'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { initialValuesForAvailability } from '../../helpers/availabilityHelper'

type Props = {
	pageMode: PageMode.ADD | PageMode.EDIT
	setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
	setMode?: React.Dispatch<React.SetStateAction<PageMode>>
	availabilityType: 'REGULAR' | 'TEMPORARY'
	selectedUserId?: string | null
	parent?: "schedulePage" | null
	selectedWeek?: dayjs.Dayjs
	availabilityListData?: AvailabilityFormData
	onAvailabilitySuccess?: (message?: string, variant?: string) => void
	showSchedulerAvailabilitySideBar?: boolean
	setShowSchedulerAvailabilitySideBar?: React.Dispatch<React.SetStateAction<boolean>>
}

function AvailabilitySideBar({ pageMode, setMode, setShowSideBar, setToastMessage, availabilityType, selectedUserId, parent, selectedWeek, availabilityListData, onAvailabilitySuccess, showSchedulerAvailabilitySideBar, setShowSchedulerAvailabilitySideBar }: Props) {

	// STATE VARIABLES
	const [ refreshKey, setRefreshKey ] = useState(0)

	// CONTEXT VARIABLE
	const { showSideBar } = useContext(CommonCtx)
	const { setAvailabilityData } = useContext(ScheduleManagementCtx)
	
	const onClose = () => {
		if(setMode)setMode(PageMode.ADD)
		setShowSideBar(false)
		if(setShowSchedulerAvailabilitySideBar) setShowSchedulerAvailabilitySideBar(false)

		setAvailabilityData(initialValuesForAvailability)
	}

	useEffect(() => {
		if(showSideBar || showSchedulerAvailabilitySideBar) {
			setRefreshKey(prev => prev + 1)
		}
	}, [showSideBar, showSchedulerAvailabilitySideBar])

	return (
		<Drawer
			open={parent === 'schedulePage' ? showSchedulerAvailabilitySideBar : showSideBar}
			onClose={() => onClose()}
			width={630}
			placement="right"
			className="custom-offcanvas"
			zIndex={1042}
			title={
				parent !== 'schedulePage' ?
					availabilityType === 'REGULAR' 
						? <JTranslation typeCase="pascal" text={REGULAR_AVAILABILITY} /> 
						: <JTranslation typeCase="pascal" text={TEMP_AVAILABILITY} />
				:
					<JTranslation text={`Set Availability for ${dayjs(selectedWeek).startOf('week').format(DATE_FORMAT)} - ${dayjs(selectedWeek).endOf('week').format(DATE_FORMAT)}`}/>
			}
			closable={false}
			maskClosable={false}
		>
			<div style={{ position: "absolute", top: 15, right: 16 }}>
					<Button
						type="text"
						icon={<CloseOutlined />}
						onClick={onClose}
					/>
				</div>
			<div className="row">
				<div className="col-md-12 col-lg-12 mb-3">
					{
						parent === 'schedulePage' ?
							<SchedulerAvailabilityForm
								key={refreshKey}
								availabilityType={availabilityType}
								onClose={onClose}
								pageMode={pageMode}
								setToastMessage={setToastMessage}
								selectedUserId={selectedUserId}
								parent={parent}
								availabilityListData={availabilityListData}
								selectedWeek={selectedWeek}
								onAvailabilitySuccess={onAvailabilitySuccess}
								setShowSchedulerAvailabilitySideBar={setShowSchedulerAvailabilitySideBar}
							/>
							:
							<AvailabilityForm
								key={refreshKey}
								availabilityType={availabilityType}
								onClose={onClose}
								pageMode={pageMode}
								setToastMessage={setToastMessage}
								selectedUserId={selectedUserId}
								parent={parent}
							/>
					}
				</div>
			</div>
		</Drawer>
	)
}

export default AvailabilitySideBar
