import { AxiosResponse } from "axios";
import { AlertVariant, HttpMethods, PermissionKeys, SubPermissionKeys } from "../constants/constants";
import { getErrorMessage } from "./utils";
import { Payload, UserRolesList, UserGlobalSettings } from "../constants/staticTypes";
import { UseMutationResult } from "react-query";
import { userRolesApi, userGlobalConfigApi, qrCodeLabelApi } from "../constants/apiEndPoints";
import HttpServiceHelper from "./httpServiceHelper";
import { JobRoleList } from "./workTimeScheduleHelper";

// payload for fetching user roles
export const getUserRoles = () => {
    return HttpServiceHelper({
        url: `${userRolesApi}/?limit=500`,
        method: HttpMethods.GET
    });
}

export const getUserGlobalConfig = () => {
    return HttpServiceHelper({
        url: userGlobalConfigApi,
        method: HttpMethods.GET
    });
}

export const updateQrCodeLabel = (
    userConfigMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    data: UserGlobalSettings,
    onSuccess: (message: string, data: UserGlobalSettings, variant: string) => void,
    onError: (res: string, variant: string) => void
) => {
    userConfigMutation.mutate(
        {
            url: qrCodeLabelApi,
            method: HttpMethods.POST,
            data: data,
        },
        {
            onSuccess: (res) => onSuccess(res.data.message, res.data.data, AlertVariant.SUCCESS),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    )
}

// get new item for adding new role
export const getItemForNewRole = (item: UserRolesList) => {
    let permissions = item.rolePermissions.map(permission => {
        let newSubPermissions = permission.permissions.map(subItem => {
            return {
                key: subItem.key,
                name: subItem.name,
                value: false
            };
        });
        return {
            permissionName: permission.permissionName,
            permissionKey: permission.permissionKey,
            isEnabled: false,
            permissions: newSubPermissions
        };
    })
    return {
        id: "",
        roleName: "",
        roleDescription: "",
        isDefault: false,
        isActive: true,
        rolePermissions: permissions
    };
}

// update role
export const updateRole = (
    updateRoleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    item: UserRolesList | undefined,
    onUpdateRoleSuccess: () => void,
    onError: (errorMessage: string) => void) => {
    updateRoleMutation.mutate({
        url: userRolesApi,
        method: HttpMethods.PUT,
        data: item
    }, {
        onSuccess: onUpdateRoleSuccess,
        onError: (res) => onError(getErrorMessage(res))
    });
}

// delete role
export const deleteRole = (
    deleteRoleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    roleId: string,
    onDeleteSucces: (roleId: string) => void,
    onError: (errorMessage: string) => void) => {
    deleteRoleMutation.mutate({
        url: userRolesApi,
        method: HttpMethods.DELETE,
        data: {
            id: roleId
        }
    }, {
        onSuccess: () => onDeleteSucces(roleId),
        onError: (res) => onError(getErrorMessage(res))
    });
}

// add new custom role
export const addRole = (
    addRoleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    item: UserRolesList | undefined,
    onAddRoleSuccess: (item: UserRolesList) => void,
    onError: (errorMessage: string) => void
) => {
    addRoleMutation.mutate({
        url: userRolesApi,
        method: HttpMethods.POST,
        data: item
    },
        {
            onSuccess: (res) => onAddRoleSuccess(res.data?.data),
            onError: (res) => onError(getErrorMessage(res))
        });
}

// get default keys for accordion
export const getAccordionDefaultKeys = (selectedItem: UserRolesList | undefined) => {
    return selectedItem?.rolePermissions.map(rolePermission => rolePermission.permissionKey);
}

// update user selection checkbox
export const getItemSelectedState = (selectedItem: UserRolesList, index: number, checkedState: boolean,
    subItemKey: string) => {
    const permissionKey = selectedItem.rolePermissions[index].permissionKey;

    // additional check for redeem in manage coupon
    if (permissionKey === PermissionKeys.MANAGE_COUPON &&
        ((subItemKey === SubPermissionKeys.REDEEM && checkedState) ||
            (subItemKey === SubPermissionKeys.VALIDITY_CHECK && !checkedState))) {
        subItemKey = "all";
    }

    let rolePermissions = selectedItem.rolePermissions.map(rolePermission => {
        if (rolePermission.permissionKey === permissionKey) {
            let uncheckedItemCount = 0;
            let totalItemCount = rolePermission.permissions.length;
            const permissions = rolePermission.permissions.map(subItem => {
                if (subItem.key === subItemKey || subItemKey === "all") {
                    subItem = {
                        ...subItem,
                        value: checkedState
                    }
                }
                if (!subItem.value) {
                    uncheckedItemCount++;
                }
                return subItem;
            });
            let isEnabled = uncheckedItemCount === totalItemCount ? false : true;
            if (totalItemCount === 0) {
                isEnabled = checkedState;
            }

            // skip toggling main permission if allowMakeAsDisabled is false
            if ('allowMakeAsDisabled' in rolePermission && !rolePermission.allowMakeAsDisabled && rolePermission.isEnabled) {
                // TODO: remove `'allowMakeAsDisabled' in rolePermission` when `allowMakeAsDisabled` added in all permission keys
                isEnabled = rolePermission.isEnabled
            }

            rolePermission = {
                ...rolePermission,
                isEnabled: isEnabled,
                permissions: [...permissions]
            }
        }
        return rolePermission;
    });
    return {
        ...selectedItem,
        rolePermissions: [...rolePermissions]
    };
}

export const transformRoleToGroupOptions = (data: JobRoleList[]) => {
    const result: any[] = [];

    data.forEach(item => {
        const departmentId = item.jobRoleDepartmentId ?? 'NULL';
        const departmentName = item.jobRoleDepartment?.departmentName ?? 'Others';
        let department = result.find(dept => dept.jobRoleDepartmentId === departmentId);

        if (!department) {
            department = {
                jobRoleDepartmentId: departmentId ?? 'NULL',
                departmentName: departmentName ?? 'Others',
                items: []
            };
            result.push(department);
        }
        department.items.push(item);
    });

    return result;
}