import React, { useEffect, useState } from 'react';
import { ColorPicker, Space, theme } from 'antd';
import { cyan, generate, green, presetPalettes, red, orange, magenta, purple } from '@ant-design/colors';
import type { ColorPickerProps, GetProp } from 'antd';
import { CHOOSE_COLOR } from '../../constants/strings';

type Color = Extract<GetProp<ColorPickerProps, 'value'>, string | { cleared: any }>;
type Format = GetProp<ColorPickerProps, 'format'>;
type Presets = Required<ColorPickerProps>['presets'][number];
type ColorPaletteProps = {
  onColorChange: (hex: string) => void; // Callback to pass the hex string to the parent
  colorHexFromParent: string;
}

function genPresets(presets = presetPalettes) {
  return Object.entries(presets).map<Presets>(([label, colors]) => ({ label, colors, key: label }));
}

const HorizontalLayout = ({ onColorChange, colorHexFromParent = "#91caff" }: ColorPaletteProps) => {
  const { token } = theme.useToken();
  const [colorHex, setColorHex] = useState<Color>(colorHexFromParent);
  const [formatHex, setFormatHex] = useState<Format | undefined>("hex");

  const hexString = React.useMemo<string>(
    // @ts-ignore
    () => (typeof colorHex === "string" ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    orange,
    cyan,
    magenta,
    purple,
  });

  const customPanelRender: ColorPickerProps["panelRender"] = (
    _,
    { components: { Picker, Presets } }
  ) => (
    <div>
      <span>
        <Presets />
      </span>
      <span>{CHOOSE_COLOR}</span>
    </div>
  );

  useEffect(() => {
    onColorChange(hexString)
  }, [hexString]) // eslint-disable-line

  return (
    <div className='d-flex align-items-center'>
      <span>
        <ColorPicker
          defaultValue={token.colorPrimary}
          // styles={{ popupOverlayInner: { width: 480 } }}
          presets={presets}
          panelRender={customPanelRender}
          format={formatHex}
          value={colorHex}
          // @ts-ignore
          onChange={setColorHex}
          onFormatChange={setFormatHex}
        />
      </span>
      <span className='m-2'>{CHOOSE_COLOR}</span>
    </div>
  );
};

const ColorPalette = ({ onColorChange, colorHexFromParent }: ColorPaletteProps) => (
  <Space direction="vertical">
    <Space>
      <HorizontalLayout onColorChange={onColorChange} colorHexFromParent={colorHexFromParent} />
    </Space>
  </Space>
);

export default ColorPalette