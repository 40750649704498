import { ADD_NEW_SHIFT, NO_WORK_SHIFT_ADDED } from '../constants/strings'
import { AddGlobalStaffingCount, DayOfWeek, MinimumEmployeeCountType, Payload, Reservations, Roles, ShiftAssignment } from '../constants/staticTypes'
import { AlertVariant, DATE_FORMAT, HttpMethods } from '../constants/constants'
import { AxiosResponse } from 'axios'
import { Button } from 'primereact/button'
import { FieldArrayRenderProps } from 'formik'
import { formatTimeToAmPm, getErrorMessage } from './utils'
import { JTranslation } from './jTranslate'
import { omit } from 'lodash'
import { staffingRequirementsApi, scheduleApi } from '../constants/apiEndPoints'
import { SwapRequestStatus, SwapStaffDetails } from './swapScheduleHelper'
import { UseMutationResult } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import dayjs, { Dayjs } from 'dayjs'
import HttpServiceHelper from './httpServiceHelper'
import isBetween from 'dayjs/plugin/isBetween'; // Import the weekday plugin
import isoWeek from 'dayjs/plugin/isoWeek'; // Import the isoWeek plugin
import moment from 'moment'
import weekday from 'dayjs/plugin/weekday'; // Import the weekday plugin

dayjs.extend(weekday); // Extend dayjs with weekday plugin
dayjs.extend(isoWeek); // Extend dayjs with isoWeek plugin
dayjs.extend(isBetween); // Extend dayjs with isBetween plugin

export type Holidays = {
	startDate: string; // "YYYY-MM-DD" format
	endDate: string; // "YYYY-MM-DD" format
	startTime: string | null;
	endTime: string | null;
	timeLabel: string | null;
	durationType: 'full-day' | 'half-day' | 'custom'; // assuming possible values based on context
	title: string;
	isOpenForBusiness: boolean;
	isPaid: boolean;
	isApproved: boolean;
	isPublished: boolean | null;
	actionStatus: 'draft' | 'pending' | 'completed' | 'cancelled'; // assuming possible values based on context
};

export type ConvertedData = {
	roles: Roles[];
};

export type UnavailableDay = {
	id: string;
	tenantStaffId: string;
	startDate: string;
	endDate: string;
	startTime: string;
	endTime: string;
	type: "full-day" | "time-off"
	reason: string | null;
	note: string;
	actionStatus: "APPROVED" | "PENDING" | "REJECTED" | "CANCELLED";
	tenantId: string;
	deletedBy: string | null;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	tenant_id: string;
	tenant_staff_id: string;
	approved_tenant_staff_id: string | null;
	deleted_by: string | null;
};

export type AvailableTime = {
	to: string;
	from: string;
};

export type Event = {
	id: string;
	isRecurring: boolean;
	startDate: string;
	endDate: string;
	tenantId: string;
	tenantStaffId: string;
	approvedTenantStaffId: string | null;
	deletedBy: string | null;
	actionStatus: "APPROVED" | "PENDING" | "REJECTED" | "CANCELLED";
	note: string | null;
	type: "full-day" | "time-off";
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	dateRange: string;
	notes: string;
	weekday: string;
	isAllDay: boolean;
	isAvailable: boolean;
	availableTimes?: AvailableTime[];
};

export type Availability = {
	[key: string]: Event[];
};


export type UpdateDraftMetaData = {
	scheduleId: string;
	scheduleMetadata: ScheduleMetadata;
}

export type SwapRequestDetails = {
	actionStatus: SwapRequestStatus;
	createdAt: string;
	fromShiftDetails: { fromEndTime: string; fromStartTime: string; fromRole: string; scheduleDate: string; shiftName: string; };
	fromShiftId: string;
	fromTenantStaff: SwapStaffDetails;
	fromTenantStaffId: string;
	id: string;
	requestType: 'swap' | 'pool';
	scheduleId: string;
	tenantId: string;
	toShiftDetails: { toEndTime: string; toStartTime: string; toRole: string; scheduleDate: string; shiftName: string; };
	toShiftId: string;
	toTenantStaff: SwapStaffDetails;
	toTenantStaffId: string;
	updatedAt: string;
}

export type ScheduleMetadata = {
	staffRequirement: StaffRequirement[];
	customEvents: CustomEvent[];
}

export type PublishedSchedule = {
	scheduleId: string;
	scheduleStartDate: string;
	scheduleEndDate: string;
	id: string;
	tenantId: string;
	tenantStaffId: string;
	scheduleDate: string;
	assignedShifts: AssignedShift;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	schedule_id: string;
	tenant_staff_id: string;
	tenant_id: string;
	fromSwapRequests: SwapRequestDetails[];
	toSwapRequests: SwapRequestDetails[];
}

export type PublishedScheduleExtended = {
	id: string;
	startDate: string;
	endDate: string;
	tenantId: string;
	actionStatus: string;
	publishedAt: string;
	scheduleMetadata: ScheduleMetadata;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	tenant_id: string;
	shifts: PublishedSchedule[];
	availabilities: Availability;
	unavailableDays: UnavailableDay[];
	holidays: Holidays[];
	mandatoryDays: MandatoryDay[];
}

export type PoolData = {
	status: SwapRequestStatus
	toShiftId: string
	requestType: 'pool' | 'swap'
	swapRequestId: string
}

export type AssignedShift = {
	shiftName: string
	startTime: string
	endTime: string
	role: string
	roleId: string
	departmentId: string
	isClose: boolean
	isBd: boolean
	isPoolRequested?: boolean
	poolData?: PoolData
	oldData?: PoolData
}

export type AssignShiftsByDate = {
	[date: string]: AssignedShift[];
};

export type AddOrUpdateSchedule = {
	scheduleId: string;
	tenantStaffId: string;
	assignShiftsByDate: AssignShiftsByDate;
};

export type PublishDraftedScheduleId = {
	scheduleId: string;
	scheduleData?: ScheduleIdsResponse | null;
};

export type CopySchedulePayloadType = {
	scheduleId: string;
	startDate: string;
	endDate: string;
};

export type JobRoleList = {
	id: string;
	jobRoleName: string;
	jobRoleDescription: string;
	jobRoleDepartmentId: string;
	createdAt: string; // ISO date string
	isActive: boolean | null;
	jobRoleDepartment: JobRoleDepartment;
	isAvailableForSchedule: boolean;
	styleDetails: StyleDetails;
};

export type JobRoleDepartment = {
	id: string;
	departmentName: string;
	departmentDescription: string | null;
};

export type StyleDetails = {
	backgroundColor: string; // Hex color code
	color: string; // Hex color code
};

export type JobRole = {
	id: string;
	tenantStaffId: string;
	jobRoleId: string;
	effectiveFrom: string;
	effectiveEnd: string | null;
	isCurrent: boolean;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	job_role_id: string;
	tenant_staff_id: string;
	jobRole: {
		id: string;
		tenantId: string;
		jobRoleName: string;
		jobRoleDescription: string;
		isActive: boolean | null;
		jobRoleDepartmentId: string;
		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
		tenant_id: string;
		job_role_department_id: string;
		jobRoleDepartment: {
			id: string;
			tenantId: string;
			departmentName: string;
			departmentDescription: string;
			isActive: boolean;
			createdAt: string;
			updatedAt: string;
			deletedAt: string | null;
			tenant_id: string;
		};
		styleDetails: StyleDetails
	};
};

export type MandatoryDay = {
	id: string;
	tenantId: string;
	startDate: string;
	endDate: string;
	isApproved: boolean | null;
	isDraft: boolean | null;
	isPublished: boolean | null;
	timeLabel: string;
	durationType: string;
	title: string | null;
	actionStatus: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	tenant_id: string;
};

export type Staff = {
	id: string;
	email: string | null;
	firstName: string;
	lastName: string;
	preferredName: string;
	signedUrl: string;
	photoThumbnail: string | null;
	jobRoles: JobRole[];
	shifts: ShiftsByDate;
	availabilities: Availability;
	unavailableDays: UnavailableDay[];
};

export type EventFormData = {
	id: string,
	guests: number,
	time: dayjs.Dayjs,
	desc: string,
	guestName: string,
}

export const initialEventFormValues: EventFormData = {
	id: uuidv4(),
	guests: 0,
	time: dayjs().add(30 - dayjs().minute() % 30, 'minute').second(0).millisecond(0),
	desc: '',
	guestName: ''
}

export type CustomEvent = {
	date: string;
	dayId: number;
	dayName: string;
	eventData: EventFormData[]; // You can specify a more detailed type if you know the structure of the event data
};

export type StaffRequirement = {
	date: string; // ISO date string
	dayId: number;
	dayName: string;
	customCount: number | null;
}

export type Schedule = {
	id: string;
	startDate: string;
	endDate: string
	actionStatus: 'draft' | 'published' | 'clear-draft' | '';
	publishedAt: string | null;
	scheduleMetadata: ScheduleMetadata;
}

export type ScheduleData = {
	holidays: Holidays[];
	mandatoryDays: MandatoryDay[];
	schedule: Schedule;
	staffs: Staff[];
};

export type ScheduleIdInfo = {
	id: string
	startDate: string
	endDate: string
	actionStatus: 'draft' | 'published'
	publishedAt: null | string
}

export type ScheduleIdsResponse = {
	draft: ScheduleIdInfo
	published: ScheduleIdInfo
}

export type DraftResponse = {
	id: string;
	startDate: string;
	endDate: string;
	actionStatus: string;
	publishedAt: string | null;
	scheduleMetadata: ScheduleMetadata;
}

export type CreateDraftResponse = {
	id: string
	startDate: null
	endDate: null
	actionStatus: string
	publishedAt: null
	created: true
}

export type StartAndEndDates = {
	startDate: string,
	endDate: string
}

export type ScheduleId = {
	scheduleId: string,
}
export interface Shift {
	departmentId: string;
	departmentName: string;
	date: string
	day: DayOfWeek
	role: string
	roleId: string
	shiftName: string
	startTime: string
	endTime: string
	isClose: boolean
	isBd: boolean
}
export interface ExtendedShift extends Shift {
	assignedShifts: Shift
	id: string
}
export interface StaffCount {
	date: string;
	dayId: number;
	dayName: string;
	customCount: number | null;
}
export interface EmployeeScheduleData {
	preferredName: string;
	staffName: string;
	staffId: string;
	staffData: Staff;
	roles: string[],
	staffRequirement: StaffRequirement[]
	monday: Shift[];
	tuesday: Shift[];
	wednesday: Shift[];
	thursday: Shift[];
	friday: Shift[];
	saturday: Shift[];
	sunday: Shift[];
	customEvents: CustomEvent[] | []
}
export interface StaffScheduleData {
	day: DayOfWeek | "";
	isHoliday: boolean;
	reservations: Reservations[] | [];
	mergeSlot: string;
	schedule: Shift[] | [];
}
export interface StaffScheduleViewData {
	data: StaffScheduleData[];
}
export interface ScheduleRow {
	time: TimeOfDay;
	// mergeSlot: string,
	schedules: {
		monday: StaffScheduleData;
		tuesday: StaffScheduleData
		wednesday: StaffScheduleData
		thursday: StaffScheduleData;
		friday: StaffScheduleData;
		saturday: StaffScheduleData;
		sunday: StaffScheduleData;
	}
}
export interface ScheduleViewData {
	data: ScheduleRow[];
}

export const days: DayOfWeek[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export interface Reservation {
	eventName: string;
	guestCount: number;
	eventTime: string;
}

type getDate = {
	selectedWeek: dayjs.Dayjs,
	day: string,
	format?: string,
}

export interface DropDown {
	name: string
	code: string
}

export interface Location {
	name: string
	code: string
}

export interface Shifts {
	name: string
	code: string
}

export interface StaffCounts {
	expected: number;
	current: number;
}

interface WeeklyStaffSchedule {
	[day: string]: StaffCounts;
}

export interface ShiftTransformed {
	scheduleDate: string;
	isBd: boolean;
	role: string;
	roleId: string;
	endTime: string;
	isClose: boolean;
	shiftName: string;
	startTime: string;
}

export interface TransformedData {
	scheduleId: string;
	scheduleStartDate: string;
	scheduleEndDate: string;
	id: string;
	tenantId: string;
	tenantStaffId: string;
	assignedShifts: ShiftTransformed[];
	availabilities: Availability;
	unavailableDays: UnavailableDay[];
	holidays: Holidays[];
	mandatoryDays: MandatoryDay[];
}

export const getTimeOffMessage = ({ unavailability }: { unavailability: UnavailableDay[] }, date: string) => {
	// Filter unavailability based on the matching date
	const filteredDays = unavailability.filter(
		(timeOff) => timeOff.startDate === date || timeOff.endDate === date
	);

	// Return the mapped JSX for the filtered days
	return filteredDays.map((timeOff, index) => {
		if (timeOff.actionStatus === 'APPROVED') {
			if (timeOff.type === 'full-day') {
				return (
					<div key={index}>
						<i className="ri-calendar-schedule-line"></i>
						<span style={{ marginRight: "3px", borderBottom: "none" }}>
							<JTranslation typeCase="none" text="Time Off:" />
						</span>
						<span style={{ color: "red", borderBottom: "none" }}>
							<JTranslation typeCase="none" text="Full Day" />
						</span>
					</div>
				);
			} else {
				return (
					<div key={index}>
						<i className="ri-calendar-schedule-line"></i>
						<span style={{ marginRight: "3px", borderBottom: "none" }}>
							<JTranslation typeCase="none" text="Time Off:" />
						</span>
						<span style={{ color: "red", borderBottom: "none" }}>
							<JTranslation
								typeCase="none"
								text={`${formatTimeToAmPm(timeOff.startTime)} to ${formatTimeToAmPm(timeOff.endTime)}`}
							/>
						</span>
					</div>
				);
			}
		} else if (timeOff.actionStatus === 'PENDING') {
			return (
				<div key={index}>
					<i className="ri-calendar-schedule-line"></i>
					<span style={{ marginRight: "3px", borderBottom: "none" }}>
						<JTranslation typeCase="none" text="Time Off:" />
					</span>
					<span style={{ color: "#fbaa07", borderBottom: "none" }}>
						<JTranslation typeCase="none" text="Pending" />
					</span>
				</div>
			);
		}
	});
};

export const transformData = (data: PublishedScheduleExtended[]): TransformedData[] => {
	const result: TransformedData[] = [];
	const groupedData: { [key: string]: TransformedData } = {};
	if (data.length) {
		const { availabilities, unavailableDays, mandatoryDays, holidays } = data[0];
		data[0].shifts.forEach(shift => {
			if (!groupedData[shift.tenantStaffId]) {
				groupedData[shift.tenantStaffId] = {
					scheduleId: shift.scheduleId,
					scheduleStartDate: shift.scheduleStartDate,
					scheduleEndDate: shift.scheduleEndDate,
					id: shift.id,
					tenantId: shift.tenantId,
					tenantStaffId: shift.tenantStaffId,
					assignedShifts: [],
					availabilities,
					unavailableDays,
					mandatoryDays,
					holidays
				};
			}

			groupedData[shift.tenantStaffId].assignedShifts.push({
				scheduleDate: shift.scheduleDate,
				isBd: shift.assignedShifts.isBd,
				role: shift.assignedShifts.role,
				roleId: shift.assignedShifts.roleId,
				endTime: shift.assignedShifts.endTime,
				isClose: shift.assignedShifts.isClose,
				shiftName: shift.assignedShifts.shiftName,
				startTime: shift.assignedShifts.startTime
			});
		});
	}

	for (const key in groupedData) {
		if (groupedData.hasOwnProperty(key)) {
			result.push(groupedData[key]);
		}
	}

	return result;
}

export const staffCountByDay: WeeklyStaffSchedule = {
	monday: { expected: 0, current: 0 },
	tuesday: { expected: 0, current: 0 },
	wednesday: { expected: 0, current: 0 },
	thursday: { expected: 0, current: 0 },
	friday: { expected: 0, current: 0 },
	saturday: { expected: 0, current: 0 },
	sunday: { expected: 0, current: 0 }
};

export const checkStaffSchedule = (schedule: WeeklyStaffSchedule): boolean => {
	for (const day in schedule) {
		if (schedule.hasOwnProperty(day)) {
			const { current, expected } = schedule[day];
			if (current !== expected) {
				return false;
			}
		}
	}
	return true;
};

export const locations: DropDown[] = [
	{ name: 'Kitchen', code: 'K' },
	{ name: 'Front of House', code: 'FOH' },
	{ name: 'Bar', code: 'B' },
	{ name: 'Dining Area', code: 'DA' },
	{ name: 'Management Office', code: 'MO' },
	{ name: 'Storage', code: 'S' },
	{ name: 'Reception', code: 'R' },
	{ name: 'Outdoor Patio', code: 'OP' },
	{ name: 'Private Dining Room', code: 'PDR' },
	{ name: 'Restrooms', code: 'RS' },
]

export type TimeOfDay = '12:00 AM' | '12:30 AM' | '1:00 AM' | '1:30 AM' | '2:00 AM' | '2:30 AM' | '3:00 AM' | '3:30 AM' | '4:00 AM' | '4:30 AM' | '5:00 AM' | '5:30 AM' | '6:00 AM' | '6:30 AM' | '7:00 AM' | '7:30 AM' | '8:00 AM' | '8:30 AM' | '9:00 AM' | '9:30 AM' | '10:00 AM' | '10:30 AM' | '11:00 AM' | '11:30 AM' | '12:00 PM' | '12:30 PM' | '1:00 PM' | '1:30 PM' | '2:00 PM' | '2:30 PM' | '3:00 PM' | '3:30 PM' | '4:00 PM' | '4:30 PM' | '5:00 PM' | '5:30 PM' | '6:00 PM' | '6:30 PM' | '7:00 PM' | '7:30 PM' | '8:00 PM' | '8:30 PM' | '9:00 PM' | '9:30 PM' | '10:00 PM' | '10:30 PM' | '11:00 PM' | '11:30 PM';

export const timeArray: TimeOfDay[] = ['12:00 AM', '12:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM', '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'];

export const initialAddOrUpdateSchedule: AddOrUpdateSchedule = {
	scheduleId: '',
	tenantStaffId: '',
	assignShiftsByDate: {
		'': [
			{
				shiftName: '',
				startTime: '',
				endTime: '',
				role: '',
				roleId: '',
				departmentId: '',
				isBd: false,
				isClose: false
			},
		],
	},
}

export const initialScheduleData: EmployeeScheduleData[] = [
	{
		staffId: '',
		staffName: '',
		preferredName: '',
		staffData: {} as Staff,
		roles: [],
		staffRequirement: [],
		customEvents: [],
		monday: [],
		tuesday: [],
		wednesday: [],
		thursday: [],
		friday: [],
		saturday: [],
		sunday: [],
	},
]

export const initialDataMinimumEmployeeCount: MinimumEmployeeCountType = {
	availability: [
		{ dayName: 'Monday', dayId: '1', minCount: '' },
		{ dayName: 'Tuesday', dayId: '2', minCount: '' },
		{ dayName: 'Wednesday', dayId: '3', minCount: '' },
		{ dayName: 'Thursday', dayId: '4', minCount: '' },
		{ dayName: 'Friday', dayId: '5', minCount: '' },
		{ dayName: 'Saturday', dayId: '6', minCount: '' },
		{ dayName: 'Sunday', dayId: '0', minCount: '' },
	],
}

export const initialDataShiftAssignment: ShiftAssignment = {
	availability: [
		{ day: 'monday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'tuesday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'wednesday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'thursday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'friday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'saturday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'sunday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
	],
}

export const initialScheduleStatus: Schedule = {
	id: '',
	startDate: '',
	endDate: '',
	actionStatus: '',
	publishedAt: '',
	scheduleMetadata: {
		staffRequirement: [],
		customEvents: []
	}
}

export function isDateInSelectedWeek({ startDate, endDate, selectedWeek }: { startDate: string, endDate: string, selectedWeek: dayjs.Dayjs }): boolean {
	const start = dayjs(startDate);
	const end = dayjs(endDate);

	const weekStart = selectedWeek.startOf('week'); // Assumes week starts on Sunday
	const weekEnd = selectedWeek.endOf('week');     // Ends on Saturday

	const isStartWithinWeek = start.isBetween(weekStart, weekEnd, 'day', '[]');
	const isEndWithinWeek = end.isBetween(weekStart, weekEnd, 'day', '[]');

	return isStartWithinWeek && isEndWithinWeek;
}

export const isTimeWithinSchedule = ({ time, shift }: { time: TimeOfDay, shift: Shift }): boolean => {
	const timeFormat = 'hh:mm A';
	const givenTime = moment(time, timeFormat);
	const startTime = moment(shift.startTime, timeFormat);
	const endTime = moment(shift.endTime, timeFormat);

	return givenTime.isSameOrAfter(startTime) && givenTime.isSameOrBefore(endTime);
};

export function isDateInRange({ startDate, endDate, date }: { startDate: string, endDate: string, date: string }): boolean {
	// Parse the dates using Moment.js
	const targetDate = moment(date, 'YYYY-MM-DD');
	const start = moment(startDate, 'YYYY-MM-DD');
	const end = moment(endDate, 'YYYY-MM-DD');

	// Check if the date is within the range (inclusive)
	return targetDate.isBetween(start, end, undefined, '[]');
}

const getJobRolesNames = (jobRoles: JobRole[]) => {
	return jobRoles.map((role) => {
		const { jobRole } = role
		return jobRole?.jobRoleName
	})
}
export interface ShiftsByDate {
	[date: string]: ExtendedShift[];
}

// Function to get all assigned shifts for a specific weekday
const getAllAssignedShifts = ({ data, weekDay }: { data: Staff, weekDay: DayOfWeek }) => {
	const { shifts } = data
	const assignedShifts: Shift[] = [];
	for (const date in shifts) {

		if (shifts.hasOwnProperty(date)) {
			const parsedDate = dayjs(date);
			const dayOfWeek = parsedDate.format('dddd').toLowerCase(); // Get the day of the week in full format, e.g., 'Monday'

			if (dayOfWeek === weekDay) {
				const tempShifts = shifts[date];
				tempShifts.forEach(shift => {
					assignedShifts.push(shift);
				});
			}
		}
	}

	return assignedShifts;
};

export const formatApiStaffData = ({ scheduleData, filterBy }: { scheduleData: ScheduleData, filterBy?: 'SWAP' | 'SCHEDULE' }) => {
	const { staffs, schedule } = scheduleData
	const formattedSchedules: EmployeeScheduleData[] = staffs.map((staff): EmployeeScheduleData | null => {
		if (filterBy && filterBy === 'SWAP' && !Object.values(staff.shifts).length) return null

		const { id, preferredName, jobRoles } = staff
		const roles = getJobRolesNames(jobRoles)
		return {
			staffId: id,
			staffName: preferredName,
			staffData: staff,
			preferredName: preferredName,
			roles: roles.length ? roles : ['No role assigned'],
			staffRequirement: schedule.scheduleMetadata.staffRequirement,
			customEvents: schedule.scheduleMetadata.customEvents,
			monday: getAllAssignedShifts({ data: staff, weekDay: 'monday' }),
			tuesday: getAllAssignedShifts({ data: staff, weekDay: 'tuesday' }),
			wednesday: getAllAssignedShifts({ data: staff, weekDay: 'wednesday' }),
			thursday: getAllAssignedShifts({ data: staff, weekDay: 'thursday' }),
			friday: getAllAssignedShifts({ data: staff, weekDay: 'friday' }),
			saturday: getAllAssignedShifts({ data: staff, weekDay: 'saturday' }),
			sunday: getAllAssignedShifts({ data: staff, weekDay: 'sunday' }),
		}
	}).filter((staff) => staff !== null) as EmployeeScheduleData[]
	return formattedSchedules
}

export const weeklyEmployeeCountValidationSchema = Yup.object().shape({
	availability: Yup.array().of(
		Yup.object().shape({
			minCount: Yup.string()
				.test('is-number', 'Staff count must be a number', (value = '') => {
					return /^\d+$/.test(value)
				})
				.required('Staff count is required'),
		})
	),
})

export const getDateOfWeekday = ({ selectedWeek, day, format = 'MMM D, YYYY' }: getDate): string => {
	const normalizedWeekday = day.toLowerCase()
	let resultDate = selectedWeek.startOf('isoWeek')

	const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
	const targetDayIndex = daysOfWeek.indexOf(normalizedWeekday)

	while (resultDate.day() !== targetDayIndex) {
		resultDate = resultDate.add(1, 'day')
	}

	return resultDate.format(format)
}

export const selectionTemplate = (option: DropDown, props: any) => {
	if (option) {
		return (
			<div className="flex align-items-center">
				<div>{<JTranslation text={option.name} />}</div>
			</div>
		)
	}

	return <span>{<JTranslation text={props.placeholder} />}</span>
}

export const depSelectionTemplate = (option: any, props: any) => {
	if (option) {
		return (
			<div className="flex align-items-center">
				<div>{<JTranslation text={option.depName} />}</div>
			</div>
		)
	}

	return <span>{<JTranslation text={props.placeholder} />}</span>
}

export const shiftSelectionTemplate = (option: any, props: any) => {
	const formattedStartTime = option && formatTimeToAmPm(option.startTime);
	const formattedEndTime = option && formatTimeToAmPm(option.endTime);

	if (option) {
		return (
			<div className="flex align-items-center">
				<div>{formattedStartTime} - {formattedEndTime}</div>
			</div>
		)
	}

	return <span>{<JTranslation text={props.placeholder || ''} />}</span>
}

export const optionTemplateWithTime = ({ startTime, endTime, name }: { name: string; startTime: string; endTime: string }) => {
	const formattedStartTime = formatTimeToAmPm(startTime);
	const formattedEndTime = formatTimeToAmPm(endTime);

	return (
		<div className="flex align-items-center">
			<div className='d-flex flex-row align-items-center justify-content-between'>
				<div>{formattedStartTime} - {formattedEndTime}</div>
				<small style={{ fontSize: '0.75rem' }}>{name}</small>
			</div>
		</div>
	);
}

export const optionTemplate = ({ name }: { name: string; startTime: string; endTime: string }) => {
	return (
		<div className="flex align-items-center">
			<div>{<JTranslation text={name} />}</div>
		</div>
	);
}

export const depOptionTemplate = ({ depName }: { depName: string; startTime: string; endTime: string }) => {
	return (
		<div className="flex align-items-center">
			<div>{<JTranslation text={depName} />}</div>
		</div>
	);
}

type addProps = { arrayHelpers: FieldArrayRenderProps }
export const AddAssignmentCard = ({ arrayHelpers }: addProps) => {
	return (

		<div className='no-work-shift p-4'>
			<i className="ri-file-list-3-line"></i>
			<p className='mb-0 title-nowork'>{NO_WORK_SHIFT_ADDED}</p>
			<button
				type='button'
				className="btn btn-custom-primary-outline"
				onClick={() => addEmptyRow({ arrayHelpers })}
			>
				{ADD_NEW_SHIFT}
			</button>
		</div>

	)
}

export const AddCardButton = ({ arrayHelpers }: addProps) => {
	return (
		<div>
			<button
				type='button'
				className="btn btn-custom-primary-outline"
				onClick={() => addEmptyRow({ arrayHelpers })}
			>
				{ADD_NEW_SHIFT}
			</button>
		</div>
	)
}

export const AddShiftButton = ({ arrayHelpers }: addProps) => {
	return (
		<div className='mt-4'>
			<Button type="button" label="Add" icon="pi pi-plus" className='p-button-primary-outline' onClick={() => addShift({ arrayHelpers })} outlined />
		</div>
	)
}

export const addEmptyRow = ({ arrayHelpers }: addProps) => {
	arrayHelpers.push({
		name: "",
		code: "",
		departmentId: "",
		shifts: [
			{
				bd: false,
				close: false,
				code: "",
				endTime: "",
				name: "",
				startTime: "",
			},
		],
	});
}

export const addShift = ({ arrayHelpers }: addProps) => {
	arrayHelpers.push({
		bd: false,
		close: false,
		code: "",
		endTime: "",
		name: "",
		startTime: "",
	})
}

export const validationSchema = Yup.object().shape({
	availability: Yup.array().of(
		Yup.object().shape({
			day: Yup.string(),
			roles: Yup.array().of(
				Yup.object().shape({
					name: Yup.string().required("Please select a role"),
					code: Yup.string().required("Please select a role"),
					roleDepartmentId: Yup.string(),
					roleDepartmentName: Yup.string(),
					departmentId: Yup.string(),
					shifts: Yup.array().of(
						Yup.object()
							.shape({
								code: Yup.string().required("Please select a shift"),
								// name: Yup.string().required("Please select a shift"),
								close: Yup.bool(),
								bd: Yup.bool(),
								startTime: Yup.string().required('Required'),
								endTime: Yup.string().required('Required'),
							})
							.test("close-bd-exclusive", "", function (value) {
								const { close, bd } = value;
								if (close && bd) {
									return this.createError({
										path: `${this.path}.bd`,
										message: "You cannot select both close and business decline",
									});
								}
								return true;
							})
							.test('is-valid-end-time', '', function (value) {
								const { startTime, endTime } = value;
								const start = dayjs(startTime, "HH:mm:ss", true)
								const end = dayjs(endTime, "HH:mm:ss", true)

								if (end.isSameOrBefore(start)) {
									return this.createError({
										path: `${this.path}.endTime`,
										message: "End time must be greater than start time",
									});
								}
								return true
							})
					),
				})
					// Custom validation to check if either `roleDepartmentId` or `departmentId` is present
					.test(
						"role-department-id-or-department-id", "", function (value) {
							const { roleDepartmentId, departmentId } = value;
							if (!roleDepartmentId && !departmentId) {
								return this.createError({
									path: `${this.path}.departmentId`,
									message: "Please select an area",
								});
							}
							return true;
						}
					)
			),
		})
	),
});


export interface Weekday {
	day: string;
	date: string;
}

export const generateWeekdayDates = (date: Dayjs): Weekday[] => {
	const weekdays: Weekday[] = [];
	let currentDate: Dayjs = date.startOf('week'); // Start from Monday

	for (let i = 0; i < 7; i++) {
		weekdays.push({
			day: currentDate.format('dddd').toLowerCase(), // Get the lowercase weekday name
			date: currentDate.format(DATE_FORMAT), // Format the date as required
		});
		currentDate = currentDate.add(1, 'day'); // Move to the next day
	}

	return weekdays;
};

export const generateTwoWeekdayDates = (date: Dayjs): Weekday[] => {
	const weekdays: Weekday[] = [];
	let currentDate: Dayjs = date.startOf('week'); // Start from Monday

	for (let i = 0; i < 14; i++) {
		weekdays.push({
			day: currentDate.format('dddd').toLowerCase(), // Get the lowercase weekday name
			date: currentDate.format(DATE_FORMAT), // Format the date as required
		});
		currentDate = currentDate.add(1, 'day'); // Move to the next day
	}

	return weekdays;
};

export const getWeekRange = (selectedDate: dayjs.Dayjs) => {
	const startOfWeek = selectedDate.isoWeekday(1); // Start of the week (Monday)
	const endOfWeek = selectedDate.isoWeekday(7);   // End of the week (Sunday)

	return {
		startDate: startOfWeek.format('YYYY-MM-DD'),
		endDate: endOfWeek.format('YYYY-MM-DD')
	} as StartAndEndDates;
};

export const getScheduleId = (draftedScheduleId: string, publishedScheduleId: string): string => {
	if (!draftedScheduleId && publishedScheduleId) {
		// Case 1: Return publishedScheduleId if draft is empty
		return publishedScheduleId;
	} else if (draftedScheduleId && publishedScheduleId) {
		// Case 2: Return draftedScheduleId if both are present
		return draftedScheduleId;
	} else if (draftedScheduleId && !publishedScheduleId) {
		// Case 3: Return draftedScheduleId if draftedScheduleId is present and publishedScheduleId is empty
		return draftedScheduleId;
	} else {
		// Default case: Return an empty string or handle as needed
		return '';
	}
}

// add global staffing requirements API 
export const addGlobalStaffingApi = (
	customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: AddGlobalStaffingCount,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	customMenuMutation.mutate(
		{
			url: staffingRequirementsApi,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// fetch global staffing requirements
export const getGlobalStaffing = () => {
	return HttpServiceHelper({
		url: staffingRequirementsApi,
		method: HttpMethods.GET,
	})
}

export const fetchDraftedAndPublishedScheduleIds = (param: StartAndEndDates) => {
	return HttpServiceHelper({
		url: `${scheduleApi}/all`,
		method: HttpMethods.POST,
		data: param,
	})
}

export const createDraftSchedule = (param: StartAndEndDates) => {
	return HttpServiceHelper({
		url: `${scheduleApi}/draft?flatDateWithUser=false`,
		method: HttpMethods.POST,
		data: param,
	})
}

export const fetchStaffsWithSchedulesAndShift = ({ scheduleId }: ScheduleId) => {
	return HttpServiceHelper({
		url: `${scheduleApi}/staffs_with_shifts/${scheduleId}`,
		method: HttpMethods.POST,
		data: { 'flatDataWithUser': false, 'filterJobRoleAvailableForSchedule': true },
	})
}

export const fetchUserPublishedListForWeek = ({ tenantStaffId, dateRange, separateBySchedule = false }: { tenantStaffId: string, dateRange: StartAndEndDates, separateBySchedule?: boolean }) => {
	return HttpServiceHelper({
		url: `${scheduleApi}/shifts/${tenantStaffId}/published`,
		method: HttpMethods.POST,
		data: {
			...dateRange,
			separateBySchedule,
		},
	})
}

// Add / update schedule to user
export const addOrUpdateScheduleApi = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: AddOrUpdateSchedule,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/draft/shifts`,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// Add / update schedule to user
export const publishDraftedSchedule = (
	scheduleMutation: UseMutationResult<
		AxiosResponse<any, any>,
		unknown,
		Payload,
		void
	>,
	param: PublishDraftedScheduleId,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	const { scheduleId, scheduleData } = param;
	scheduleMutation.mutate(
		{
			url: scheduleData?.published.id
				? `${scheduleApi}/${scheduleId}/publish?isForcePublish=true`
				: `${scheduleApi}/${scheduleId}/publish`,
			method: HttpMethods.POST,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	);
};

// CLONE - Create a draft clone from published
export const cloneDraftFromPublished = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: PublishDraftedScheduleId,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/${param.scheduleId}/clone`,
			method: HttpMethods.POST
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// update draft metadata
export const updateDraftMetadata = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: UpdateDraftMetaData,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/meta_data`,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// Delete an existing draft
export const deleteExistingDraft = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: PublishDraftedScheduleId,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/draft/${param.scheduleId}`,
			method: HttpMethods.DELETE,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const copyEntireSchedule = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: CopySchedulePayloadType,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/${param.scheduleId}/new_week/clone`,
			method: HttpMethods.POST,
			// remove scheduleId from param
			data: omit(param, ['scheduleId']),
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}