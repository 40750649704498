import { AxiosResponse } from 'axios';
import { E164Number } from 'libphonenumber-js/core';
import { FileBrowserProps, FileData } from 'chonky';
import { FormikErrors, FormikTouched } from 'formik';
import { HttpMethods, SpecialMenuIdPrefixes } from './constants';
import { QueryClient, UseMutationResult } from 'react-query';
import dayjs, { Dayjs } from 'dayjs';
import React, { Dispatch, SetStateAction } from 'react';
import { Availability, UnavailableDay } from '../helpers/workTimeScheduleHelper';
import { DataTableStateEvent } from 'primereact/datatable';

// user login
export type UserLogin = {
  username: string;
  password: string;
};

// payload for api
export type Payload = {
  queryClient?: QueryClient;
  url: string;
  method?: HttpMethods;
  noAuth?: boolean;
  headers?: {};
  data?: {};
  isFile?: boolean;
  isSignedUrl?: boolean;
};

// user roles list type
export type UserRolesList = {
  id: string;
  roleName: string;
  roleDescription: string;
  rolePermissions: Permissions[];
  isActive: boolean;
  isDefault: boolean;
};

export type Role = {
  id: string
  roleName: string
}

type Permissions = {
  permissionKey: string;
  permissionName: string;
  permissions: PermissionEntity[];
  isEnabled: boolean;
  allowMakeAsDisabled?: boolean; //TODO: Remove optional, when all permissions will have this key in API
};

export type PermissionEntity = {
  key: string;
  name: string;
  value: boolean;
  allowMakeAsDisabled?: boolean; //TODO: Remove optional, when all permissions will have this key in API
};

export type Tenants = {
  id: string;
  tenantName: string;
}

// users list type
export type Users = {
  jobRoles: JobRole[];
  createdAt: number;
  deletedAt: string | null;
  email: string;
  preferredName: string;
  firstName: string;
  lastName: string;
  loginUserName: string;
  hireDate: string;
  id: string;
  phoneNumber: string;
  photoKey: string | null;
  photoKeyThumbnail: string | null;
  picture: string;
  posNumber: string;
  signedUrl: string | undefined;
  isActive: boolean;
  userType: string;
  passwordLessLoginOnly: boolean;
  sendPasswordCreation: boolean;
  preferredLangKey: string;

  activeTenants: Tenants[];
  staffPermissionRoles: RolePermissions[];
  isAdmin: boolean;
  tenantId?: string;
  tenantDetails?: {
    tenantId: string,
    tenantName: string
  };
};

export type UserFormData = {
  photoKey: string | null;
  preferredName: string;
  firstName: string;
  lastName: string;
  loginUserName: string;
  email: string;
  phoneNumber: E164Number | string | undefined;
  password: string;
  jobRoles: string[];
  posNumber: string;
  hireDate: Date | null;
  passwordLessLoginOnly: boolean;
  sendPasswordCreation: boolean;
  sendPasswordCreationTo: string;
  staffId: string;
  preferredLangKey: string;
  staffPermissionRoles: string[];
};

// Error Message
export type ErrorMessage = {
  data: {
    message: string;
    errors: [
      {
        key: string;
        message: string;
        param: string;
      }
    ];
  };
};

// F&B Menu
export type Menu = {
  categoryName: string;
  menuName?: string;
  id: string;
  isActive: boolean;
  parentCategoryId: string | null;
  photoKey: string | null;
  rowStatus: string;
  searchTerm: string;
  signedUrl: string;
  subCategory: SubCategory[];
  sortOrder?: number;
  specificDatesSchedules?: [];
  periodicSchedules: PeriodicSchedules;
  photoThumbnails: string;
  scheduleUpdatedAt?: number | '';
  scheduleType: ScheduleType;
  urlSlug?: string;
};

// F&B Menu Sub Item
export type SubCategory = {
  categoryName: string;
  categoryDescription: string;
  id: string;
  isActive: boolean;
  menuItems: MenuItem[];
  sortOrder?: number;
  urlSlug?: string;
};

// F&B Menu Item
export type FnBGlobalSetting = {
  marketPriceLabel: string;
  showMenuCardPrice: boolean;
  showMenuSuggestedItemCardPrice: boolean;
  qrCodeSettings: {
    qrCodeTopLabel: string;
    includeLogo: boolean;
    qrCodeBottomLabel: string;
  };
  menuResetTimeInMinutes: number;
};

export type GuestFnbGlobalSetting = {
  marketPriceLabel: string;
  showMenuCardPrice: boolean;
  showMenuSuggestedItemCardPrice: boolean;
  menuResetTimeInMinutes: number;
};

export type MenuItem = {
  isAvailable: boolean;
  isMarketPrice: boolean;
  additionalInfo: AdditionalInfo[];
  categoryId?: string | null;
  itemCategoryId: string | undefined | null;
  itemMenuId?: string;
  id?: string;
  isActive?: boolean;
  itemDescription: string;
  itemName: string;
  itemPrices: Price[];
  addonPrices: Price[];
  selectedAddons: Addon[];
  selectedAddonsData?: SelectedAddOnsData[];
  selectedAddonGroups?: SelectedAddonGroup[];
  selectedAddonsGroupsData?: SelectedAddonsGroupsData[];
  selectedKeyIngredients?: SingleIngredient[];
  selectedKeyIngredientsData?: Ingredient[];
  itemTag: string;
  itemType: string;
  photoKeys: string[];
  photoThumbnails?: string[];
  rowStatus?: string;
  searchTerm?: string;
  signedPhotoKeys?: string[];
  signedVideoKeys?: string[];
  tenantId?: string;
  videoKeys: string[];
  suggestedItems: (string | undefined)[];
  suggestedItemsData?: MenuItem[];
  historyInfo: HistoryInfo;
  sortOrder?: number;
  categoryDetails?: categoryDetails;
  urlSlug?: string;
};

export type SingleMenuItem = {
  id: string;
};

export type categoryDetails = {
  photoKey: null | string;
  categoryName: string;
  categoryDescription: string;
  createdAt: number;
  id: string;
  isActive: null;
  parentCategoryId: string;
  rowStatus: string;
  searchTerm: string;
  updatedAt: number;
  signedUrl: null | string;
  sortOrder: number;
};

export type MenuItemsListProps = {
  setEditItem: React.Dispatch<React.SetStateAction<Menu | SubCategory | MenuItem | undefined>>;
  setAddMenu: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  showAddMenu: boolean;
  menuList: [] | Menu[];
  selectedMenu: Menu | undefined;
  setSelectedMenu: React.Dispatch<React.SetStateAction<Menu | undefined>>;
  setSelectedCategory: React.Dispatch<React.SetStateAction<SubCategory | undefined>>;
  deleteItem: string | undefined;
  setDeleteItem: React.Dispatch<React.SetStateAction<string | undefined>>;
  setAddCatgory: React.Dispatch<React.SetStateAction<boolean>>;
  showAddCategory: boolean;
  selectedCategory: SubCategory | undefined;
  setAddItem: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItem: React.Dispatch<React.SetStateAction<MenuItem | undefined>>;
  showAddItem: boolean;
  selectedItem: MenuItem | undefined;
  onSuccess: (message: string) => void;
  displayToast: (message: string, variant: string) => void;
  setMenuList: React.Dispatch<React.SetStateAction<[] | Menu[]>>;
  upsertMenu: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>;
  currentMenuIndex: number;
  setCurrentMenuIndex: React.Dispatch<React.SetStateAction<number>>;
  currentCategoryIndex: number;
  setCurrentCategoryIndex: React.Dispatch<React.SetStateAction<number>>;
  setFormType: Dispatch<SetStateAction<boolean>>
};

export type CustomMenuItemsListProps = {
  setFormType: Dispatch<SetStateAction<boolean>>
  deleteItem: string | undefined;
  displayToast: (message: string, variant: string) => void;
  loading: boolean;
  onSuccess: (message: string) => void;
  selectedCategory: CustomMenuCategoriesAndItems | undefined;
  selectedItem: MenuItem | undefined;
  selectedMenu: CustomMenu | undefined;
  setAddCatgory: React.Dispatch<React.SetStateAction<boolean>>;
  setAddItem: React.Dispatch<React.SetStateAction<boolean>>;
  setAddMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteItem: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEditItem: React.Dispatch<React.SetStateAction<CustomMenuCategoriesAndItems | MenuItem | undefined>>;
  setSelectedCategory: React.Dispatch<React.SetStateAction<CustomMenuCategoriesAndItems | undefined>>;
  setSelectedItem: React.Dispatch<React.SetStateAction<MenuItem | undefined>>;
  setSelectedMenu: React.Dispatch<React.SetStateAction<CustomMenu | undefined>>;
  showAddCategory: boolean;
  showAddItem: boolean;
  showAddMenu: boolean;
  upsertMenu: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>;
};

// Add items
export type ModalState =
  | 'ADD_DEFAULT_PRICE'
  | 'CHANGE_DEFAULT_PRICE'
  | 'DELETE_PRICE';

// Menu Add Item Form
export type Price = {
  fieldName: string;
  fieldValue: string;
  isDefault: boolean;
};

export type AdditionalInfo = {
  textContent: string;
  imageKeys: string[];
  signedImageKeys: string[];
};

export type HistoryInfo = {
  title: string;
  subTitle: string;
  description: string;
  originCountry: string;
  otherHtmlContents: string;
  photoKeys: string[];
  signedPhotoKeys: string[];
  color: string;
  bouquet: string;
  taste: string;
  denomination: string;
  additionalInfo: AdditionalInfo[];
};

// survey data type
export type Survey = {
  id: string;
  surveySetName: string;
  urlSlug: string;
  createdAt?: number;
  isDefault?: boolean;
  isActive: boolean;
  isGameAvailable: boolean;
  surveyCategories: SurveyCategories[];
};

export type SurveyCategories = {
  id?: string;
  surveyCategoryName: string;
  surveyCategoryType: string;
  isActive: boolean;
  surveyQuestions: SurveyQuestion[];
  isDeleted: boolean;
};

export type SurveyQuestion = {
  id?: string;
  surveyQuestionName: string;
  surveyQuestionType: string;
  surveyQuestionAnswer?: string | number | boolean;
  isRequired: boolean;
  isActive: boolean;
  isDeleted: boolean;
  matrixColumns?: [
    {
      name: string;
      cellType: string;
      isRequired: boolean;
      choices: string[];
    }
  ];
  matrixRows?: string[];
  matrixRowsAnswers?: MatrixRowAnswer;
};

export type MatrixRowAnswer = {
  [x: string]: {
    firstColumn: number;
  };
};

// Guest Info
export type GuestInfo = {
  anniversaryDate: string;
  birthDate: string;
  createdAt: string;
  emailId: string;
  fullName: string;
  isActive: boolean;
  phoneNumber: string;
  rowStatus: string;
  id: string;
  totalSurveyCount: number;
  totalVisitCount: number;
  zipCode: string;
  lastVisitedAt: string;
};

// Pagination Data
export type PaginationData = {
  Count: number;
  ScannedCount: number;
  lastKey: string;
};

// Public Server Info
export type ServerInfo = {
  preferredName: string;
  id: string;
  posNumber: string;
};

// Survey Report
export type SurveyReport = {
  createdAt: number;
  guestUserId: string;
  guestUserData: GuestInfo;
  id: string;
  isActive: boolean;
  serverId: string;
  serverName: string;
  serverPosNumber: string;
  surveyAnswers: {
    surveyCategories: SurveyCategories[];
    surveySetId: string;
    surveySetName: string;
  }[];
};

// Game Data Type
export type Game = {
  id: string;
  prizeWinning: string;
  prizeDescription: string;
  prizeType: string;
  stockRepeatInterval: string;
  stockCount: number | string;
  couponCodeMaxDaysValidity: number | string;
  winningCriterias: {
    minVisitCount: number | string;
    weightage: number | string;
    locationRadiusInMiles?: number | string;
    zipCodes?: string[];
  };
  isDefault?: boolean;
  isJackpot?: boolean;
  isActive: boolean;
  isBetterLuck?: boolean;
  betterLuckCount?: number;
};

export type GameForm = {
  prizeWinning: string;
  prizeDescription: string;
  prizeType: string;
  stockCount: string;
  couponCodeMaxDaysValidity: string;
  stockRepeatInterval: string;
  winningCriterias: {
    minVisitCount: string;
    weightage: string;
    locationRadiusInMiles: string;
    zipCodes: string[];
  };
};

// react select dropdown options
export type DropDownOptions = {
  value: string;
  label: string;
};

// move feature type
export type Move = 'item' | 'category' | 'copy-item-to' | 'copy-category-to';

// file manager feature types
export type ApiFolderAndFileDS = {
  breadCrumbsText?: string;
  createdAt: number;
  deletedAt: string | null;
  fileName: string;
  files: ApiFolderAndFileDS[];
  folderName: string;
  id: string;
  dbId: string;
  uuid: string;
  isActive: boolean;
  isDir: boolean;
  name: string;
  parentFolderId: string;
  parentFolders?: ApiFolderAndFileDS[];
  PK: string;
  signedUrl: string;
  SK: string;
  updatedAt: number;
};

export type ChonkyFolder = {
  id: string;
  name: string;
  isDir: boolean;
  childrenIds: string[];
  parentId: string;
  modDate: string;
};

export type ChonkyFolderNode = {
  [fileId: string]: ChonkyFolder;
};

export type ChonkyRootFolder = {
  rootFolderId: string;
  fileMap: ChonkyFolderNode;
  isDir: boolean;
};

export type ChonkyParentAndChild = {
  parentFolderId: string;
  childFolderId: string;
};

export type SaveFileToDb = {
  fileKey: string;
  fileName: string;
  parentFolderId?: string | null;
  parentFolderUuid?: string;
};

export type SaveFolderToDb = {
  folderId: string;
  folderName: string;
  parentFolderId?: string | null;
  parentFolderUuid?: string;
};

export type UploadedFileProp = {
  fileKey: string;
  fileName: string;
};

export type RenameFolderOnDb = {
  folderName: string;
  folderId?: string;
  folderUuid?: string;
};

export type RenameFileOnDb = {
  fileId?: string;
  fileName: string;
};

export type MoveFileOnDb = {
  fileId: number | string;
  parentFolderUuid?: string;
  parentFolderId?: string | null;
};

export type MoveFolderOnDb = {
  folderId?: string;
  folderUuid?: string;
  parentFolderId?: string | null;
  parentFolderUuid?: string | null;
};

export type DeleteFolderOnDb = {
  folderId?: string;
  folderUuid?: string;
};
export interface CustomFileData extends FileData {
  parentId?: string;
  childrenIds?: string[];
}
export interface CustomFileMap {
  [fileId: string]: CustomFileData;
}

export interface RenameFolderProps {
  files: FileData[];
  folderName: string;
}
export interface RenameFileProps {
  files: FileData[];
  fileName: string;
}
export interface VFSProps {
  masterFsMap: ChonkyRootFolder;
  globalSearchValue: string;
  setGlobalSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setReCallListApi: React.Dispatch<React.SetStateAction<boolean>>;
  VFSBrowserProps?: Partial<FileBrowserProps>;
}

export interface DndDetails {
  destination: FileData;
  draggedFile: FileData;
}

export type SearchProps = {
  globalSearchValue: string;
  setGlobalSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setReCallListApi: React.Dispatch<React.SetStateAction<boolean>>;
};

export type HandleActionProps = {
  changeDragAndDropDetails: (param: DndDetails) => void;
  createFolder: Function;
  data: any;
  download: Function;
  fileManagerMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    Payload,
    void
  >;
  inputFile: React.MutableRefObject<null>;
  modalInputText: string;
  moveFiles: Function;
  onError: (message: string, variant: string) => void;
  renameFile: Function;
  renameFolder: Function;
  tempFile: FileData[];
  updateDocToBeViewed: Function;
  setCurrentFolderId: React.Dispatch<React.SetStateAction<string>>;
  setCurrentName: React.Dispatch<React.SetStateAction<string>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalTitle: React.Dispatch<React.SetStateAction<string>>;
  setSelectedFilesForAction: React.Dispatch<
    React.SetStateAction<CustomFileData[]>
  >;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDocViewer: React.Dispatch<React.SetStateAction<boolean>>;
  setTempFile: React.Dispatch<React.SetStateAction<ApiFolderAndFileDS[]>>;
  setVariant: React.Dispatch<React.SetStateAction<string>>;
  setWarning: React.Dispatch<React.SetStateAction<boolean>>;
};

export type ZipItem = {
  isDir: boolean;
  isUuid: boolean;
  itemId: string;
};

export type DownloadZip = {
  items: ZipItem[];
};

export type DeleteZip = {
  items: ZipItem[];
};

export type DownloadZipApiResponse = {
  fileUrlSigned: string;
};

export type Doc = {
  uri: string;
};

export type DocViewerDocs = {
  docs: Doc[];
};

export type CommonModalProps = {
  callback?: Function;
  disableProceed?: boolean;
  disableActionButtons?: boolean;
  hideProceed?: boolean;
  hideCancel?: boolean;
  hideFooter?: boolean;
  hideCloseButton?: boolean;
  additionalActions?: boolean;
  additionalActionsButtons?: JSX.Element;
  modalBodyCss?: string;
  modalContent: any;
  modalSize?: 'xl' | 'md' | 'lg' | 'sm' | undefined;
  onHide: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  title: string;
  proceedButtonName?: string;
  cancelButtonName?: string;
};

export type docToBeViewed = {
  doc: Doc[];
  fileName: string;
};

// file type verification
export type FileTypeOption = {
  id: number;
  name: string;
  displayLabel: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
};

export type ExtractedFile = {
  id: number;
  uuid: string;
  name: string;
  fileKey: string;
  parentId: null | string;
  fileRawData: null | Object;
  createdAt: string;
  updatedAt: string;
  uploadedUserName: string;
};

export type ExtractedFileData = {
  createdAt: string;
  extractedFormattedData: {};
  extractedFormattedExpenseData: [];
  file: ExtractedFile;
  fileId: number;
  fileKey: string;
  id: number;
  identifiedEntityUserApproval: boolean;
  isUserChangedInitialEntity: boolean;
  signedUrl: string;
  textractIdentifiedEntity: string;
  textractIdentifiedInitialEntity: string;
  textractJobStatus: TextractJobStatus;
  textractJobStatusLabel: string;
  textractJobStatusMessage: string;
  updatedAt: string;
  vendorName: string;
};

export type InvoiceDataList = {
  createdAt: string;
  extractedFormattedData: {};
  extractedFormattedExpenseData: [];
  fileId: number;
  fileKey: string;
  id: number;
  textractJobStatus: string;
  textractJobStatusLabel: string;
  textractJobStatusMessage: string;
  textractIdentifiedEntity: string;
  textractIdentifiedInitialEntity: string;
  isUserChangedInitialEntity: null;
  updatedAt: string;
  identifiedEntityUserApproval: boolean;
  file: {};
  signedUrl: string;
  vendorName: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceReceiptDate: string;
  invoiceReceiptId: null | string;
  dueDate: null | string;
};

export type ExtractedFileApiResponse = {
  item_list: ExtractedFileData[];
  total: number;
  current_page: number;
};

export type ListAllInvoiceApiResponse = {
  item_list: InvoiceDataList[];
  total: number;
  current_page: number;
};

export type PrimeReactDropDown = {
  name: string
  code: string
}

export type ScheduleDepartmentDropDown = {
  depName: string
  departmentId: string
};

export type ScheduleRoleDropDown = PrimeReactDropDown & {
  roleDepartmentId: string;
  roleDepartmentName: string;
};

export interface ShiftDropDown extends PrimeReactDropDown {
  startTime: string,
  endTime: string
}

export type FileTypeDropdownOption = {
  name: string;
  code: string;
};

export type ThumpsUpOrDown = {
  extractResultId: number;
  isExtractedTypeCorrect: boolean;
};

export type ChangeFileType = {
  extractResultId: number;
  textractIdentifiedEntity: string;
};

export type Pagination = {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
};

export type Search = {
  searchValue: string;
  basicRows: number;
  pageNumber: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  storeCurrentTabName: Function;
};

export type ActionButtonToBeEnabled = {
  documentId: number;
};

export type FileTypeProcess = 'NOT_STARTED' | 'STARTED' | 'COMPLETED';

export type TextractJobStatus = 'IN_PROGRESS' | 'SUCCEEDED' | 'USER_REJECTED';

export type InvoiceFilter = {
  title: string;
  display: boolean;
};

export type FileTypeVerificationTabs =
  | 'verify_type'
  | 'verify_data'
  | 'invoices';

export type PosLoginResponse = {
  id: string;
  preferredName: string;
  picture: string;
  posNumber: string;
  status: string;
  userName: string;
  userType: string;
  signedUrl: string;
};

export type Coupon = {
  id: string;
  couponCode: string;
  couponStatus: string;
  couponValidTillFormatted: string;
  gamePrizeDetails: {
    id: string;
    prizeWinning: string;
    prizeDescription: string;
  };
  guestEmail: string;
  maxDaysValid: number;
  statusChangeAt: number;
  createdAt: number;
};

export type PersonalInfo = {
  emailId: string;
  fullName: string;
  serverId: string;
  serverName: string;
  serverPosNumber: string;
  guestDeviceId: string | null;
  guestExpectedId: string | null;
  anniversaryDate: Date | null;
  birthDate: Date | null;
  zipCode: string;
  phoneNumber: string;
};

export type MaskedPersonalInfo = {
  anniversaryDate: string;
  birthDate: string;
  emailId: string;
  fullName: string;
  isActive: boolean;
  phoneNumber: string;
  zipCode: string;
  guestDeviceId?: null | string;
  guestExpectedId?: null | string;
  serverId?: string;
  serverName?: string;
  serverPosNumber?: string;
  totalVisitCount: number;
  userId?: string;
  id?: string;
};

// survey page modes
export type SurveyMode = 'New' | 'Edit' | 'Masked';

// welcome screen config
export type WelcomeScreenData = {
  imageKey: string;
  welcomeRichTextData: string;
};

export type WelcomeConfig = {
  imageKey: string;
  signedUrl: string;
  welcomeRichTextData: string;
};

export type UploadedWelcomeImage = {
  key: string;
  signedUrl: string;
};

// menu schedule
export type ScheduleMenuId = {
  id: string;
  menuName?: string;
};

export type ScheduleType = 'periodic' | 'specificDate ' | 'always' | '';

export type ScheduleTime = {
  startTime: string | null;
  endTime: string | null;
};

export type PeriodicSchedules = {
  [day: string]: ScheduleHours;
};

export type ScheduleHours = {
  isActive: boolean;
  selectedHours: ScheduleTime[];
};

export type MenuSchedule = {
  id: string;
  isActive?: boolean;
  parentCategoryId?: null;
  rowStatus?: string;
  specificDatesSchedules?: [];
  periodicSchedules: PeriodicSchedules;
  scheduleUpdatedAt?: number | '';
  scheduleType: ScheduleType;
};

export type DisableMenuSchedule = {
  id: string;
  scheduleType: ScheduleType;
};

export type SurveyLookupTable = {
  [key: number]: number;
};

export type SurveyMarks = {
  [key: number]: {
    style?: {
      transform?: string;
      left?: string;
    };
    label: string;
  };
};
export type LocationState = {
  menuId: string;
  message: string;
  selectionIndex: number;
};

export type Addon = {
  addonDescription?: string | null;
  addonDiscount?: string;
  addonId: string;
  addonImageKey?: string | null;
  addonName: string;
  addonPrice: string;
  id?: string;
  signedImageUrl?: string | null;
};

export type NewAddon = {
  addonDescription?: string | null;
  addonDiscount?: string;
  addonId?: string | null;
  addonImageKey?: string | null;
  addonName: string;
  addonPrice: string;
};

export type DeleteAddon = {
  addonId: string | undefined;
};

export type SingleAddon = {
  addonId: string;
};

export type SelectedAddOnsData = {
  addonId: string;
  addonName: string;
  addonPrice: string;
  addonDescription: string | null;
  addonImageKey: string | null;
};

export type SelectedAddonGroup = {
  addonGroupPrice: string;
  addonGroupId: string;
  addonGroupDiscount: string | null;
};

export type SelectedAddonsGroupsData = SelectedAddonGroup & {
  groupData: {
    id: string;
    createdAt: string;
    addonGroupName: string;
    addonGroupPrice: string;
    selectedAddons: [] | null;
    rowStatus: string | null;
    selectedAddonsData: SelectedAddOnsData[];
  };
};

export type ToastMessageProps = {
  message: string;
  variant: string;
  show: boolean;
};

export type ToastMessageVariant =
  | 'success'
  | 'info'
  | 'warn'
  | 'error'
  | undefined;

export type UserGlobalSettings = {
  qrCodeLabel: string;
};

export type SingleAddOnResponse = {
  id: string;
  addonId: string;
  addonName: string;
  addonImageKey: null | string;
  signedImageUrl: null | string;
  addonDiscount: null | string;
  addonPrice: string;
  addonDescription: null | string;
  selectedMenus: SelectedMenusItem[];
};

export type SelectedMenusItem = {
  id: string;
  itemName: string;
  itemPrices: {
    fieldValue: number;
    fieldName: string;
  }[];
};

// category filter
export type MenuFilter = 'CATEGORY' | 'TAG' | 'SEARCH';

// Ingredient feature
export type Ingredient = {
  id: string;
  imageKeys: string[];
  ingredientDescription: string;
  ingredientId: string;
  ingredientName: string;
  signedImageUrls: string[];
  urlSlug?: string;
};

export type SingleIngredient = {
  ingredientId: string;
};

export type DeleteIngredientType = {
  ingredientId: string | undefined;
};

export type SingleIngredientResponse = {
  id: string;
  imageKeys: string[];
  ingredientDescription: string;
  ingredientId: string;
  ingredientName: string;
  selectedMenus: SelectedMenusItem[];
  signedImageUrls: string[];
};

export type UpdateIngredient = {
  imageKeys: string[];
  ingredientDescription: string;
  ingredientId: string;
  ingredientName: string;
};

export type CreateIngredient = {
  imageKeys: string[];
  ingredientDescription: string;
  ingredientName: string;
};

export type BreadCrumbProp = {
  data: Menu[];
  id: string;
};

export type Gallery = {
  src: string;
  type: 'image' | 'video';
  thumbnail?: string;
};

export enum AiTextLength {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
}

export enum AiImageSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type GenerateAiType =
  | ''
  | 'ingredientDescription'
  | 'menuDescription'
  | 'menuCategoryDescription'
  | 'menuItemDescription'
  | 'menuAdditionalInfo'
  | 'wineHistory'
  | 'welcomeScreen'
  | 'newsDescription'
  | 'eventDescription'
  | 'aboutUsDescription';

export type GenerateImageAiType =
  | ''
  | 'foodImage'
  | 'ingredientImage'
  | 'beverageImage'
  | 'otherImage';

export type GenerateAiDescription = {
  positiveText: string;
  negativeText: string;
  textLength: 'short' | 'medium' | 'long';
  type: GenerateAiType;
};

export type GenerateImageAiDescription = {
  positiveText: string;
  negativeText: string;
  imageSize: 'small' | 'medium' | 'large';
  type: GenerateImageAiType;
  maxResponseCount: number;
};

export type AiTextGeneratorProps = {
  callBack: Function;
  show: boolean;
  type: GenerateAiType;
};

export type AiImageGeneratorProps = {
  callBack: Function;
  type: GenerateImageAiType;
  cropType?: CropType;
};

export type AiResponse = {
  text: string;
  finish_reason: string;
  index: number;
};

export type AiImage = {
  url: string;
};

export type AiTextResponse = {
  aiResponse: AiResponse[];
};

export type AiImageResponse = {
  aiResponse: AiImage[];
};

// Custom Menu / Special Menu
export type CustomMenuForm = {
  menuName: string;
  menuDescription: string;
  photoKey: string | null;
};

export type CreateCustomMenu = {
  photoKey: string | null;
  menuName: string;
  menuCode: string;
  menuDescription: string;
  createdAt: number;
  id: string;
  isActive: boolean;
  rowStatus: 'ACTIVE' | 'INACTIVE';
  searchTerm: string;
  updatedAt: number;
  signedUrl: null;
};

export type DeleteCustomMenuType = {
  menuId: string;
};

export type CustomMenu = {
  photoKey: string | null;
  menuName: string;
  menuCode: string;
  menuDescription: string;
  createdAt: number;
  globalSettings: FnBGlobalSetting;
  id: string;
  isActive: boolean;
  rowStatus: 'ACTIVE' | 'INACTIVE';
  searchTerm: string;
  updatedAt: number;
  signedUrl: string;
  menuCategoryAndItems: CustomMenuCategoriesAndItems[];
};

export type CustomMenuGuestView = {
  photoKey: string | null;
  menuName: string;
  menuCode: string;
  menuDescription: string;
  globalSettings: FnBGlobalSetting;
  createdAt: number;
  id: string;
  isActive: boolean;
  rowStatus: 'ACTIVE' | 'INACTIVE';
  searchTerm: string;
  updatedAt: number;
  signedUrl: string;
  subCategory: CustomMenuCategoriesAndItems[];
};

export type GetSingleCustomMenuParamType = {
  menuId: string;
};

export type EditCustomMenu = {
  menuName: string;
  menuDescription: string;
  photoKey: string | null;
  menuId: string;
};

export type ChangeCustomMenuStatus = {
  menuId: string | undefined;
  status: boolean;
};

export type ChangeCustomItemStatus = {
  itemMenuId: string | undefined;
  status: boolean;
};

export type ChangeCustomCategoryStatus = {
  categoryId: string | undefined;
  status: boolean;
};

export type CustomMenuCategoriesAndItems = {
  photoKey: string | null;
  categoryName: string;
  categoryDescription: string;
  createdAt: number;
  id: string;
  isActive: boolean;
  parentCategoryId: string;
  rowStatus: string;
  searchTerm: string | null;
  updatedAt: number;
  signedUrl: string;
  sortOrder: number;
  scheduleType: string;
  periodicSchedules: PeriodicSchedules;
  specificDatesSchedules: string[];
  menuItems: MenuItem[];
};

export type CreateCustomCategoryType = {
  categoryName: string;
  categoryDescription: string;
  photoKey: string | null;
  parentCategoryId: string;
};

export type EditCustomCategoryType = {
  categoryId: string;
  categoryName: string;
  categoryDescription: string;
  photoKey: string | null;
};

export type DeleteCustomCategoryType = {
  categoryId: string;
};

export type AddExistingItemsParamType = {
  itemCategoryId: string;
  menuItemIdsInOrder: string[];
};

export type GetObjectWithPrefixProps = {
  data: CustomMenuGuestView;
  id: string;
  idPrefix:
  | SpecialMenuIdPrefixes.SPECIAL_MENU
  | SpecialMenuIdPrefixes.SPECIAL_MENU_CATEGORY
  | SpecialMenuIdPrefixes.SPECIAL_MENU_ITEM;
};

export type CustomMenuGlobalConfigResponse = {
  data: {
    data: {
      Attributes: {
        periodicSchedules: {};
        globalSettings: FnBGlobalSetting;
        menuDescription: string;
        tenantId: string;
        createdAt: number;
        specificDatesSchedules: string[];
        GSI_ROW_TYPE: string;
        menuName: string;
        isActive: boolean;
        menuCode: string;
        scheduleUpdatedAt: number;
        rowStatus: string;
        updatedAt: number;
        SK: string;
        scheduleType: string;
        PK: string;
        photoKey: string | null;
        searchTerm: string;
      };
    };
  };
};

export type CustomMenuCategorySort = {
  categoryIdsInOrder: string[];
  isParentList: true;
};

export type CustomMenuItemSort = {
  menuItemIdsInOrder: string[];
};

export type CustomMenuItemViewParam = {
  itemMenuId: string;
};

// NEWS AND EVENTS
export type NewsPost = {
  title: string;
  postTags: string;
  urlSlug: string;
  description: string;
  publishDate: Date | null;
  expiryDate: Date | null;
  photoKeys: string[];
  videoKeys: string[];
  saveAction: 'draft' | 'create';
};

export type NewsPostForApi = {
  title: string;
  postTags: string;
  urlSlug: string;
  description: string;
  publishDate: string | null;
  expiryDate: string | null;
  photoKeys: string[];
  videoKeys: string[];
  saveAction: 'draft' | 'create';
  notificationType?: 'newNews';
};

export type EventPost = {
  title: string;
  postTags: string;
  location: string;
  description: string;
  photoKeys: string[];
  videoKeys: string[];
  publishDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  startTime: string;
  endTime: string;
  urlSlug: string;
  saveAction: 'draft' | 'create';
  isRecurring: boolean;
  every?: Every;
  recurringPattern?: RecurringPattern;
  recurringStartTime?: string;
  recurringEndTime?: string;
  repeatsEveryNthDay?: number;
  repeatsEveryNthWeek?: number;
  repeatsEveryNthMonth?: number;
  repeatsEveryNthMonthRow2?: number;
  selectedMonthDay?: number;
  selectedWeekday?: SelectedWeekday;
  selectedDaysOfWeek?: SelectedWeekday[];
  selectedOrdinal?: SelectedOrdinal;
  selectedYearMonth?: SelectedYearMonth;
};

export type EventPostFormData = {
  title: string;
  postTags: string;
  location: string;
  description: string;
  photoKeys: string[];
  videoKeys: string[];
  publishDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  startTime: string;
  endTime: string;
  recurringStartTime: string;
  recurringEndTime: string;
  urlSlug: string;
  saveAction: 'draft' | 'create';
  every?: Every;
  isRecurring: boolean;
  recurringPattern?: RecurringPattern;
  recurringOptions?: RecurringOptions;
  repeatsEveryNthDay?: number;
  repeatsEveryNthWeek?: number;
  repeatsEveryNthMonth?: number;
  repeatsEveryNthMonthRow2?: number;
  selectedMonthDay?: number;
  selectedWeekday?: SelectedWeekday;
  selectedDaysOfWeek?: SelectedWeekday[];
  selectedOrdinal?: SelectedOrdinal;
  selectedYearMonth?: SelectedYearMonth;
};

export type RecurringOptions = {
  repeatsEveryNthDay?: number;
  repeatsEveryNthWeek?: number;
  repeatsEveryNthMonth?: number;
  selectedMonthDay?: number;
  selectedWeekday?: SelectedWeekday;
  selectedDaysOfWeek?: SelectedWeekday[];
  selectedOrdinal?: SelectedOrdinal;
  selectedYearMonth?: SelectedYearMonth;
} | null;

export type FetchNextOccurrence = {
  publishDate: string;
  recurringPattern: RecurringPattern;
  recurringOptions: RecurringOptions;
};

export type Every = 'every' | 'every-weekday' | undefined | '';
export type SelectedWeekday =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';
export type SelectedYearMonth =
  | 'january'
  | 'february'
  | 'march'
  | 'april'
  | 'may'
  | 'june'
  | 'july'
  | 'august'
  | 'september'
  | 'october'
  | 'november'
  | 'december';
export type SelectedOrdinal = 'first' | 'second' | 'third' | 'fourth' | 'last';
export type RecurringPattern =
  | 'daily'
  | 'weekly'
  | 'monthly'
  | ''
  | null
  | undefined;

export type StartAndEndTime = { startTime: string; endTime: string, note?: string, }

export type BusinessHoursType = {
  monday: { isOpen: boolean; dayId: number, workingHours: StartAndEndTime[] };
  tuesday: { isOpen: boolean; dayId: number, workingHours: StartAndEndTime[] };
  wednesday: { isOpen: boolean; dayId: number, workingHours: StartAndEndTime[] };
  thursday: { isOpen: boolean; dayId: number, workingHours: StartAndEndTime[] };
  friday: { isOpen: boolean; dayId: number, workingHours: StartAndEndTime[] };
  saturday: { isOpen: boolean; dayId: number, workingHours: StartAndEndTime[] };
  sunday: { isOpen: boolean; dayId: number, workingHours: StartAndEndTime[] };
};

export type RecurrenceProps = {
  errors: FormikErrors<EventPost>;
  touched: FormikTouched<EventPost>;
  every: Every;
  setNextOccurrence: Dispatch<SetStateAction<string | null>>;
};

export type EventPostForApi = {
  title: string;
  postTags: string;
  location: string;
  description: string;
  photoKeys: string[];
  videoKeys: string[];
  publishDate: string | null;
  startDate: string | null;
  endDate: string | null;
  startTime: string;
  endTime: string;
  urlSlug: string;
  saveAction: 'draft' | 'create';
  notificationType?: 'newEvent';
};

export type NewsPostGuestView = {
  id: string;
  title: string;
  postTags: string;
  urlSlug: string;
  description: string;
  publishDate: string;
  expiryDate: string;
  photoKeys: string[];
  videoKeys: string[];
  saveAction: 'draft' | 'create';
  signedPhotosKeys: string;
  signedVideoKeys: string;
};

export type EventPostGuestView = {
  id: string;
  title: string;
  postTags: string;
  location: string;
  description: string;
  photoKeys: string[];
  videoKeys: string[];
  publishDate: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  urlSlug: string;
  saveAction: 'draft' | 'create';
  signedPhotosKeys: string;
  signedVideoKeys: string;
  isRecurring: boolean;
  recurringPattern: RecurringPattern;
  recurringOptions: RecurringOptions;
  nextOccurrenceDate: string;
};

export type NewsAndEventsListType = {
  createdAt: number;
  createdAtDate: string;
  description: string;
  endDate: string | null;
  endTime: string | null;
  id: string;
  location: string;
  photoKeys: string[];
  postTags: string;
  postType: PostType.EVENT | PostType.NEWS | PostType.SURVEY;
  publishDate: string;
  publishedStatus:
  | 'DRAFT'
  | 'PUBLISHED'
  | 'CREATED'
  | 'TRASHED'
  | 'UN_PUBLISHED';
  rowStatus: 'ACTIVE' | 'INACTIVE';
  saveAction: 'draft' | 'create';
  signedPhotosKeys: string;
  signedVideoKeys: string[];
  startDate: string | null;
  startTime: string | null;
  title: string;
  updatedAt: number;
  updatedAtDate: string;
  urlSlug: string;
  videoKeys: string[];
  isRecurring: boolean;
};

export type NewsAndEventsId = {
  postId: string;
};

export type NewsAndEventsSlugId = {
  urlSlug: string;
};

export type PostResponseType = {
  createdAt: number;
  createdAtDate: string;
  description: string;
  endDate: string;
  endTime: string;
  expiryDate: string;
  id: string;
  location: string;
  photoKeys: string[];
  postTags: string;
  postType: string;
  publishDate: string;
  publishedStatus:
  | 'DRAFT'
  | 'PUBLISHED'
  | 'CREATED'
  | 'TRASHED'
  | 'UN_PUBLISHED';
  rowStatus: string;
  saveAction: 'draft' | 'create';
  signedPhotosKeys: string[];
  signedVideoKeys: string[];
  startDate: string;
  startTime: string;
  title: string;
  updatedAt: number;
  updatedAtDate: string;
  urlSlug: string;
  videoKeys: string[];
  isRecurring: boolean;
  every: Every;
  recurringPattern: RecurringPattern;
  recurringOptions: RecurringOptions;
};

export type NewsAndEventStatusType = {
  postId: string;
  postStatus: 'publish' | 'un_publish';
};

export enum NewsAndEventsSwitchStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  HIDDEN = 'hidden',
}

export enum PostType {
  EVENT = 'event',
  NEWS = 'news',
  SURVEY = 'survey',
}

export type FormatTime = {
  time: string;
  format?: string;
};

export type DateFormat = {
  date: string;
  format?: string;
};

export type DateTimeFormat = {
  dateTime: string;
  format?: string;
};

export type FormatTimeFromAntdForApiType = {
  IsoTime: string;
  format?: string;
};

export type FormatDateFromAntdForApiType = {
  date: Date | null;
  format?: string;
};

export type ParseTimeForAntdTimePickerType = {
  formattedTime: string;
  format?: string;
};

export type GetNewsAndEventsApiParams = {
  lastKey?: any;
  limit: number;
  search?: string;
};

export type SocialMediaPlatforms =
  | 'twitter'
  | 'facebook'
  | 'linkedin'
  | 'instagram'
  | 'whatsapp'
  | 'email';

export type UploadedAiImageInfo = {
  signedImageUrls: string[];
  imageKeys: string[];
};

export type TimeOffRequestTypes =
  | 'full-day'
  | 'half-day'
  | 'morning'
  | 'afternoon'
  | 'time-off';

export type TimeOffRequestFormType = {
  type: TimeOffRequestTypes;
  fromDate: string | number | Date;
  toDate: string | number | Date;
  startTime: Dayjs;
  endTime: Dayjs;
  staffId: string;
  id?: string;
  note: string;
  duration: string;
  reason: string;
  action?: string;
};

export type DurationInput = {
  type: TimeOffRequestTypes;
  fromDate: string | number | Date;
  toDate: string | number | Date;
  startTime: Dayjs;
  endTime: Dayjs;
};

export type DaysInput = {
  fromDate: string | number | Date | Dayjs;
  toDate: string | number | Date | Dayjs;
};

export type TimeOffEntry = {
  id: string;
  type: TimeOffRequestTypes;
  createdAt: number;
  submittedDate: string;
  endDate: string;
  endTime: string;
  reason: string;
  notes: string;
  rowStatus: string;
  actionStatus: TimeOffStatuses;
  startDate: string;
  startTime: string;
  updatedAt: number;
  updatedAtDate: string;
  userData: {
    preferredName: string;
    firstName: string;
    lastName: string;
    posNumber: string;
    id: string;
    signedUrl: string;
  };
  staffId?: string;
};

export type AddTimeOff = {
  staffId: string; // pass 'superadmin' if the user is super admin
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  type: TimeOffRequestTypes;
  reason: string;
  note?: string; // Optional property
  isManaged?: boolean; // property for direct approval if from scheduler page
};

export type UpdateTimeOff = {
  id: string;
  staffId: string;
  startDate: string;
  endDate: string;
  type: TimeOffRequestTypes;
  startTime?: string; // Optional property
  endTime?: string; // Optional property
  reason: string;
};

export type AddTimeOffResponse = {
  data: {
    id: string;
  };
  message: string;
  status: boolean;
};

export type AddHolidayResponse = {
  data: {
    id: string;
  };
  message: string;
  status: boolean;
};

export type MandatoryDay = {
  id: string;
  startDate: string;
  endDate: string;
  durationType: HolidayType;
  timeLabel: Options;
};

export type AddUpdateMandatoryResponse = {
  data: {
    list: MandatoryDay[];
  };
  message: string;
};

export type MandatoryFormData = {
  durationType: HolidayType;
  startDate: string | number | Date | Dayjs;
  endDate: string | number | Date | Dayjs;
  id?: string;
  timeLabel: Options;
};

export type ConfirmPopupType = {
  visible: boolean;
  buttonName: string;
  requestId: string;
  action: TimeOffStatuses;
};

export type UpdateTimeOffStatus = {
  id: string;
  staffId: string;
  startDate: string;
  endDate: string;
  type: TimeOffRequestTypes;
  startTime: string;
  endTime: string;
  reason: string;
};

export type TimeOffStatuses =
  | 'PENDING'
  | 'APPROVED'
  | 'REJECTED'
  | 'CANCELLED'
  | 'DELETE'
  | 'EDITED';

export type TimeOffRequestStatus = {
  id: string;
  newStatus: TimeOffStatuses;
  note: string;
};

export type Id = {
  id: string;
};

export type DisplayModalType = 'display-none' | '';

export type QrCodeLabelsType = {
  qrCodeTopLabel: string;
  qrCodeBottomLabel: string;
  includeLogo: boolean;
};

export type AddonGroup = {
  addonGroupId: string;
  addonGroupName: string;
  addonGroupPrice: string;
  id?: string;
  selectedAddons: string[];
  selectedAddonsData: SelectedAddOnsData[] | [];
  selectedMenus: [];
  rowStatus?: string;
};

export type NewAddonGroup = {
  id?: string;
  addonGroupId?: string | null;
  addonGroupName: string;
  addonGroupPrice: string;
  selectedAddons: string[];
};

export type DeleteAddonGroup = {
  id: string | undefined;
};

export type SingleAddonGroup = {
  id: string;
};

export type SingleAddOnGroupResponse = {
  addonGroupId: string;
  addonGroupName: string;
  addonGroupPrice: string;
  id: string;
  selectedAddons: string[];
  selectedAddonsData: SelectedAddOnsData[] | [];
  selectedMenus: [];
};

export type SetLogo = {
  imageKey: string;
  logoType: 'default' | 'small' | 'medium' | 'large' | 'favicon';
};

export type CelebrationPages = 'welcomePage' | 'allOtherPage';

export type BrandingData = {
  backgroundImage: string;
  displayName: string;
  logoUrl: string;
  logos: {
    small: string;
  };
  metaDescription: string;
  metaTitle: string;
  primaryColor: string;
  secondaryColor: string;
  enableCelebrationEffect?: boolean;
  activeCelebrationTemplate?: string;
  showCelebrationTemplateOn?: CelebrationPages[];
  celebrationEffects?: {};
  isDayLightSaving?: boolean; // TODO: will change in future
  dayLightSavingOffSetInMinutes?: number; // TODO: will change in future
  defaultDateFormat?: string; // TODO: will change in future
  defaultTimeFormat?: string; // TODO: will change in future
  defaultTimezone?: string; // TODO: will change in future
  weekStartsOn?: string;
};

export type BusinessDefaultHours = {
  isOpen: boolean;
  dayId: number,
  workingHours: StartAndEndTime[]
}

export type LocationDetails = {
  streetAddress: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  latitude: number;
  longitude: number;
  placeId: string;
  formattedAddress: string;
}

export type BrandingResponseData = {
  id: string;
  branding: BrandingData;
  appName: string;
  organization: {
    id: string
  };
  domainUrl: string;
  hostname: string;
  tenantName: string;
  businessDefaultHours: BusinessDefaultHours[];
  contactDetails: LocationDetails;
};

type HolidayType = 'full-day' | 'half-day';
type Options = 'morning' | 'afternoon';

export type HolidayData = {
  actionStatus: string;
  createdAt: number;
  description: string;
  holidayType: string;
  durationType: HolidayType;
  endDate: string;
  endTime: string;
  id: string;
  isPaid: boolean;
  rowStatus: string;
  startDate: string;
  startTime: string;
  submittedDate: string;
  timeLabel: Options;
  title: string;
  updatedAt: number;
  updatedAtDate: string;
  year: string;
  isOpenForBusiness: boolean;
};

export type HolidayFormDataType = {
  description: string;
  duration?: string;
  holidayType: string;
  durationType: HolidayType;
  endDate: string | number | Date | Dayjs;
  endTime?: string;
  id?: string;
  isPaid: boolean | null;
  isOpenForBusiness: boolean | null;
  startDate: string | number | Date | Dayjs;
  startTime?: string;
  timeLabel?: Options;
  title: string;
};

export type MenuResetTimeInMinutesType = {
  menuResetTimeInMinutes: number;
};

export type ChallengeResponse = {
  data: {
    challengeMetadata: ChallengeMetadata;
  };
  message: string;
  status: boolean;
};

export type ChallengeMetadata = {
  ChallengeName: string;
  Session: string;
  ChallengeParameters: {
    USERNAME: string;
  };
};

interface SnowFlakes {
  colors: string[];
  count: number;
  maxSize: number;
  minOpacity: number;
  maxOpacity: number;
}
interface particles {
  colors: string[];
  count: number;
  maxSize: number;
  minOpacity: number;
  maxOpacity: number;
}

export type celebrationEffectsChristmasType = {
  showEffects?: boolean;
  playChime?: boolean;
  sleighSpeedInSeconds?: string | number;
  chimeUrl?: string;
  snowFlakes?: SnowFlakes;
};

export type celebrationEffectsValentinesDayType = {
  chimeUrls?: string[];
  particles?: particles;
  playChime?: boolean;
  showEffects?: boolean;
  specialLogoUrl?: string;
  // new fields
  showCardEffects?: boolean; // Optional if it's not always included
  showCupid?: boolean;
  showSpecialLogo?: boolean; // Assuming this might be optional as well
};

export type StPatricksDayEffectsType = {
  chimeUrls?: string[];
  particles?: {
    colors: string[];
    count: number;
    maxOpacity: number;
    maxSize: number;
    minOpacity: number;
  };
  playChime?: boolean;
  showEffects?: boolean;
  specialLogoUrl?: string;
  showCardEffects?: boolean;
  showAnimatedCharacter?: boolean;
  showSpecialLogo?: boolean;
};
export type EasterEffectsType = {
  chimeUrls?: string[];
  particles?: {
    colors: string[];
    count: number;
    maxOpacity: number;
    maxSize: number;
    minOpacity: number;
  };
  playChime?: boolean;
  showEffects?: boolean;
  specialLogoUrl?: string;
  showCardEffects?: boolean;
  showAnimatedCharacter?: boolean;
  showSpecialLogo?: boolean;
};
export type CincoDeMayoEffectsType = {
  chimeUrls?: string[];
  particles?: {
    colors: string[];
    count: number;
    maxOpacity: number;
    maxSize: number;
    minOpacity: number;
  };
  playChime?: boolean;
  showEffects?: boolean;
  specialLogoUrl?: string;
  showCardEffects?: boolean;
  showAnimatedCharacter?: boolean;
  showSpecialLogo?: boolean;
  chimeUrl: string;
  animatedCharacterUrl: string;
};
export type MothersDayEffectsType = {
  chimeUrls?: string[];
  particles?: {
    colors: string[];
    count: number;
    maxOpacity: number;
    maxSize: number;
    minOpacity: number;
  };
  playChime?: boolean;
  showEffects?: boolean;
  specialLogoUrl?: string;
  showCardEffects?: boolean;
  showAnimatedCharacter?: boolean;
  showSpecialLogo?: boolean;
  chimeUrl: string;
  animatedCharacterUrl: string;
};

export type celebrationEffectsType = {
  christmasNewYear: celebrationEffectsChristmasType;
  valentinesDay: celebrationEffectsValentinesDayType;
  stPatricksDay: StPatricksDayEffectsType;
  easter: EasterEffectsType;
  cincoDeMayo: CincoDeMayoEffectsType;
  mothersDay: MothersDayEffectsType;
};

export type Shift = {
  id: string;
  shiftName: string;
  startTime: string;
  endTime: string;
};

export type ShiftForm = Omit<Shift, 'id'>;

export interface ShiftTemplate {
  id?: string;
  shiftName: string;
  startTime: string;
  endTime: string;
  actionStatus?: 'PENDING' | 'APPROVED' | 'REJECTED';
}

export type AddShiftTemplate = Omit<ShiftTemplate, 'id'>

export interface ShiftFormData {
  id: string;
  shiftName: string;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
}

export interface ShiftTemplatesData {
  shiftTemplates: ShiftTemplate[];
}

export type AvailabilityTimeFormData = {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
};

export type AvailabilityWeekFormData = {
  weekday: dayjs.Dayjs;
  isAllDay: boolean;
  isAvailable: boolean;
  availableTimes: AvailabilityTimeFormData[];
  notes?: string;
  reason?: string;
};

export type AvailabilityFormData = {
  id?: string;
  staffId: string;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  isRecurring: 'temporary' | 'recurring';
  weekdays: AvailabilityWeekFormData[];
  currentAvailabilityStatus?: 'PENDING' | 'APPROVED' | 'REJECTED';
  isManaged?: boolean;
};

export type AvailabilityTimeData = {
  from: string;
  to: string;
};

export type AvailabilityWeekData = {
  weekday: string;
  isAllDay: boolean;
  isAvailable: boolean;
  availableTimes?: AvailabilityTimeData[];
  notes?: string;
  reason?: string;
};

export type AddAvailabilityFormData = {
  staffId: string;
  startDate: string | null;
  endDate: string | null;
  isRecurring: boolean;
  weekdays?: AvailabilityWeekData[];
  isManaged?: boolean;
};

export type SingleAvailabilityFormData = {
  id?: string;
};

export type UpdateAvailabilityFormData = AddAvailabilityFormData &
  SingleAvailabilityFormData;

export type AvailabilityData = UpdateAvailabilityFormData & {
  createdAt: string;
  isDraft: boolean;
  isPublished: boolean;
  rowStatus: string;
  actionStatus: 'PENDING' | 'APPROVED' | 'REJECTED';
  isApproved: boolean;
  staff?: {
    email: string;
    firstName: string;
    hireDate: string;
    id: string;
    lastName: string;
    phoneNumber: string;
    posNumber: string;
    preferredName: string;
  };
};

export type AddAvailabilityResponse = {
  data: {
    id: string;
  };
  message: string;
  status: boolean;
};

export type OffDays = {
  timeOffs: TimeOffEntry[];
  holidays: HolidayData[];
  mandatoryDays: {
    id: string;
    createdAt: string;
    rowStatus: string;
    startDate: string;
    endDate: string;
    durationType: string;
  }[];
};

export type OffDaysParams = {
  staffId: string;
  startDate: string;
  endDate: string;
};

export type OffDaysResponse = {
  data: OffDays;
  message: string;
  status: boolean;
};

export interface Country {
  name: string;
  code: string;
  langKey: string;
  langName: string;
}

export type CropType =
  | 'LANDSCAPE'
  | 'PORTRAIT'
  | 'BANNER'
  | 'FREEFORM'
  | 'MENU'
  | 'BEVERAGE'
  | 'FOOD'
  | 'INGREDIENT'
  | 'NEWS'
  | 'EVENTS';

export type ImageType = {
  [key in CropType]: {
    width?: number;
    height?: number;
    aspectRatio?: number;
  };
};

export type Dimension = {
  width: number | undefined
  height: number | undefined
}

export type PerDayCount = {
  dayName: string;
  dayId: string;
  minCount: string;
}

export type AddGlobalStaffingCount = {
  data: PerDayCount[]
}

export type StaffCount = {
  dayName: string;
  staffCount: string;
  minCount: string;
}

export type Reservations = {
  time: string;
  guestCount: number;
}

export type DayOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export type MinimumEmployeeCountType = {
  availability: PerDayCount[];
}

export type SelectedShift = {
  name: string
  code: string
  startTime: string,
  endTime: string
  close: boolean,
  bd: boolean,
}

export type Roles = {
  name: string;
  code: string;
  roleDepartmentId: string;
  roleDepartmentName: string;
  shifts: SelectedShift[]
}

export type AvailabilityForFE = {
  day: DayOfWeek
  roles: Roles[]
  availabilities: Availability;
  unavailableDays: UnavailableDay[];
  holidays: string[];
  mandatoryDays: MandatoryDay[];
}

export type ShiftAssignment = {
  availability: AvailabilityForFE[]
}

export type ProfileFormData = {
  photoKey: string | null;
  firstName: string;
  lastName: string;
  staffId: string;
  preferredLangKey: string;
};

export type FestivalThemeFormData = {
  enableCelebrationEffect: boolean;
  activeCelebrationTemplate: string;
  showCelebrationTemplateOn: CelebrationPages[];
};

export type TimezoneInfo = {
  dayLightSavingOffSetInMinutes: number;
  defaultDateFormat: string;
  defaultTimeFormat: string;
  defaultTimezone: string;
  isDayLightSaving: boolean;
  weekStartsOn: string;
};

export type TimezoneInfoFormData = {
  dayLightSavingOffSetInMinutes: string;
  defaultDateFormat: string;
  defaultTimeFormat: string;
  defaultTimezone: string;
  isDayLightSaving: boolean;
  weekStartsOn: string;
  businessDefaultHours: BusinessDefaultHours[];
  locationDetails: LocationDetails;
};

export type AboutUsConfig = {
  imageKey: string;
  signedUrl: string;
  imagePlacement: ImagePlacement;
  aboutUsTitle: string;
  aboutUsRichTextData: string;
};

export type UploadedAboutUsImage = {
  key: string;
  signedUrl: string;
};

export type AboutUsConfigFormData = {
  imageKey: string;
  imagePlacement: ImagePlacement;
  aboutUsTitle: string;
  aboutUsRichTextData: string;
};

export type ImagePlacement = "top" | "left" | "right"

export type GetNotificationsApiParams = {
  lastKey: string | null
  limit: number
  search: string | null
}

export type Notification = {
  body: string;
  title: string;
}

export type NotificationType = 'time-off-admin' | 'time-off-user' | 'availability-admin' | 'availability-user' | 'holiday' | 'news' | 'event' | 'shift-user' | 'shift-swap-list' | 'shift-swap-schedule'

export type NotificationObject = {
  id: string; // ID
  createdAt: string;
  rowStatus: string;
  createdAtDate: string;
  topic: NotificationType; // TYPE
  tenantId: string;
  status: string; // STATUS
  timestamp: number; // DATE
  GSI_ROW_TYPE: string;
  updatedAtDate: string;
  GSI1SK: string;
  isRead: boolean;
  notification: Notification; // BODY
  GSI1PK: string;
  data: {
    volume: string;
    contents: string;
  };
  updatedAt: string;
  staffId: string;
  searchTerm: string;
  postSlug: string;
  referenceId: string;
}

export type Permission = {
  id: string;
  parentPermissionId: string | null;
  permissionId: string | null;
  moduleId: string;
  permissionKey: string;
  permissionName: string;
  permissionDescription?: string;
  isActive: boolean;
  isDefault: boolean;
  permissions: Permission[];
}

export type PermissionList = {
  id: string;
  moduleName: string;
  moduleDescription: string;
  isDefault: boolean;
  permissions: Permission[]
}

export type PermissionForm = {
  defaultPermissionId: string;
  isActive: boolean;
}

export type PermissionListForm = {
  id: string;
  permissionRoleName: string;
  permissionRoleDescription: string;
  rolePermissions: PermissionForm[]
}

export type PermissionCreationForm = Pick<PermissionListForm, 'permissionRoleName' | 'permissionRoleDescription'>;

export type RolePermissions = {
  id: string;
  permissionRoleName: string;
  permissionRoleDescription: string;
  permissionKey: string;
  isActive: boolean;
  isDefault: boolean;
  tenantId: string;
  permissions: Permission[]
  permissionRoleId?: string;
}

export type JobRole = {
  id: string;
  jobRoleName: string;
  jobRoleDescription: string;
  isActive: boolean;
  createdAt: string;
  jobRoleId?: string;
  jobRoleDepartmentId: string;
  jobRoleDepartment: {
    id: string;
    departmentName: string;
    departmentDescription: null;
  };
  isAvailableForSchedule: boolean | null;
  styleDetails: {
    backgroundColor: string
    color: null;
  };
};

export type JobRoleAddForm = Pick<JobRole, 'jobRoleName' | 'jobRoleDescription' | 'jobRoleDepartmentId' | 'isAvailableForSchedule' | 'styleDetails'>;
export type JobRoleEditForm = Pick<JobRole, 'id' | 'jobRoleName' | 'jobRoleDescription' | 'jobRoleDepartmentId' | 'isAvailableForSchedule' | 'styleDetails'>;

export type SqlPagination = {
  currentPage: number;
  from?: number;
  lastPage?: number;
  limit: number;
  nextPageUrl?: string | null;
  path?: string;
  prevPageUrl?: string | null;
  to?: number;
  total: number;
}

export type CopyUserFormData = {
  staffId: string;
  newTenantId: string;
  posNumber: string;
  staffPermissionRoles: string[];
  jobRoles: string[];
  hireDate: string;
}

export type AreaType = {
  id: string;
  departmentName: string;
  departmentDescription: string;
  createdAt: string;
  isActive: boolean;
}
export type AreaTypeFormData = Pick<AreaType, 'id' | 'departmentName' | 'departmentDescription'>;
export type AddAreaTypeFormData = Pick<AreaType, 'departmentName' | 'departmentDescription'>;

export type PaginationApi = {
  currentPage: number;
  from: number;
  lastPage: number;
  limit: number;
  nextPageUrl: string;
  path: string;
  prevPageUrl: string | null;
  to: number;
  total: number;
};

export type DatatablePagEvent = DataTableStateEvent & {
  rowsPerPageOptions: number[];
  forceUpdate: number;
  paginatorTemplate: string;
};

export type GoogleAddress = {
  city_town: string | undefined;
  country_region: string | undefined;
  state: string | undefined;
  zip_code: string | undefined;
  lat: number | undefined;
  lng: number | undefined;
  place_id: string | undefined;
  formatted_address: string | undefined;
};

export type CopyScheduleModalContent = {
  selectedWeekForCopy: dayjs.Dayjs;
  setSelectedWeekForCopy: Dispatch<SetStateAction<dayjs.Dayjs>>;
}