
import { TimePicker, TimePickerProps } from 'antd';
import './MlxTimePicker.scss';
import { detectOS } from '../../helpers/utils';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { useDebounce, useEffectOnce } from 'react-use';

type MlxTimePickerProps = TimePickerProps;

const MlxTimePicker = (props: Readonly<MlxTimePickerProps>) => {
    const mlxTimePickerRef = useRef(null);
    const uuid = uuidv4()
    let {
        className,
        popupClassName,
        size,
        use12Hours,
        clearIcon,
        format,
        placeholder,
        minuteStep,
        value,
        onChange,
        style,
        inputReadOnly,
        needConfirm,
        id,
        ...rest
    } = props;

    const [timePickerValue, setTimePickerValue] = useState<dayjs.Dayjs | null | undefined>(value ?? null)
    const [inputValue, setInputValue] = useState<string | null>(null)
    const [inputProgress, setInputProgress] = useState(false)
    const [refreshKey, setRefreshKey] = useState(0);
    const [firstMount, setFirstMount] = useState(false)

    const [, cancel] = useDebounce(
        () => {
            setInputProgress(false)
            if (inputValue) {
                updateTimer(inputValue);
            }
        },
        4000,
        [inputValue]
    );

    // Update class name
    if (!className) {
        className = '';
    }
    className = 'mlx-time-picker ' + className;

    // update id
    let dataId = id ?? '';
    dataId += `-${uuid}`

    // Disable input based on OS
    const currentOs = detectOS();
    if (!inputReadOnly) {
        inputReadOnly = ['android', 'ios'].includes(currentOs);
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        setInputProgress(true);
        // Prevent default submission and blur out the current element
        if (e.key === 'Enter') {
            e.preventDefault();
            // (mlxTimePickerRef?.current as any)?.blur()
        }
    };

    const updateTimer = (time: string, refresh = false) => {
        // parse input string to valid date/time
        const parseDebouncedValue = convertStringToTime(time);
        if (!parseDebouncedValue) return;

        const updatedDaysJsValue = dayjs(parseDebouncedValue);
        if (updatedDaysJsValue.isValid()) {
            setTimePickerValue(updatedDaysJsValue);
            if (onChange) {
                onChange(updatedDaysJsValue, updatedDaysJsValue.format('hh:mm A'));
            }
            if (refresh) {
                setRefreshKey(prev => prev + 1);
            }
            setInputValue(null);
        }
    }

    useEffect(() => {
        if (!dataId) return;

        const input = document.querySelector(`[data-id="${dataId}"]`);
        if (!input) return;

        const handleInputChange = (e: Event) => {
            const target = e.target as HTMLInputElement;
            const value = target.value;
            setInputValue(value ?? null);
        };

        input.addEventListener('keyup', handleInputChange);

        return () => {
            input.removeEventListener('keyup', handleInputChange);
        };
    }, [dataId, setInputValue])

    useEffect(() => {
        setTimePickerValue(value)
    }, [value])

    useEffectOnce(() => {
        setFirstMount(true)
    })

    return <TimePicker
        key={refreshKey}
        ref={mlxTimePickerRef}
        className={className}
        popupClassName={popupClassName ?? "custom-antd-time-picker"}
        size={size ?? "middle"}
        use12Hours={use12Hours ?? true}
        format={format ?? "hh:mm A"}
        minuteStep={minuteStep ?? 15}
        placeholder={placeholder}
        value={timePickerValue}
        onPickerValueChange={(date) => {
            setInputValue(null);
            setTimePickerValue(date);
            onChange?.(date, date.format('hh:mm A'))
        }}
        onChange={() => { }} // TODO: Do nothing for now
        style={style ?? {}}
        inputReadOnly={inputReadOnly}
        onKeyDown={handleKeyDown}
        suffixIcon={inputProgress ? <i className='pi pi-spin pi-spinner' /> : null}
        needConfirm={needConfirm ?? false}
        data-id={dataId}
        onBlur={() => {
            if (!inputValue) return;

            cancel();
            setInputProgress(false)
            updateTimer(inputValue, true)
        }}
        onFocus={() => {
            if (firstMount && !value) {
                const currentDate = dayjs().startOf('day');
                setTimePickerValue(currentDate);
                onChange?.(currentDate, currentDate.format('hh:mm A'))
                setFirstMount(false)
            }
        }}
        showNow={false}
        {...rest}
    />
};

export default MlxTimePicker;

/**
 * Converts time string to standardized 12-hour format (HH:MM AM/PM)
 * Accepts both 12-hour and 24-hour formats with any separator
 * Rounds minutes to nearest quarter (00, 15, 30, 45)
 *
 * @param timeString - Time in format: "1205pm", "12:05", "2359" etc.
 * @returns Formatted time string "HH:MM AM/PM"
 * @throws {Error} For invalid time format or values
 *
 * @example convertStringToTime("1205pm") // returns "12:00 PM"
 * @example convertStringToTime("2359")   // returns "11:45 PM"
 */
export const convertStringToTime = (timeString: string) => {
    // Remove all special characters and spaces except digits and letters
    const cleanTime = timeString.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

    // Extract time components using regex
    const matches = cleanTime.match(/(\d{1,2})(\d{2})(am|pm)?/);

    if (!matches) {
        console.error(`Invalid time format : ${timeString}`);
        return null;
    }

    let [_, hours, minutes, meridiem] = matches;

    // Convert hours and minutes to numbers
    let hoursNum = parseInt(hours);
    let minutesNum = parseInt(minutes);

    // Round minutes to nearest quarter (0, 15, 30, 45)
    const roundedMinutes = [0, 15, 30, 45];
    const nearestMinute = roundedMinutes.reduce((prev, curr) => {
        return Math.abs(curr - minutesNum) < Math.abs(prev - minutesNum) ? curr : prev;
    });
    minutesNum = nearestMinute;

    // Validate minutes
    if (minutesNum < 0 || minutesNum > 59) {
        console.error('Invalid minutes: Minutes must be between 0-59');
        return;
    }

    // Handle 24-hour format
    if (!meridiem) {
        if (hoursNum >= 24) {
            console.error('Invalid hours: Hours must be between 0-23 in 24-hour format');
            return;
        }

        // Convert 24hr to 12hr format
        if (hoursNum === 0) {
            hoursNum = 12;
            meridiem = 'am';
        } else if (hoursNum === 12) {
            meridiem = 'pm';
        } else if (hoursNum > 12) {
            hoursNum = hoursNum - 12;
            meridiem = 'pm';
        } else {
            meridiem = 'am';
        }
    } else {
        // Validate 12-hour format
        if (hoursNum < 1 || hoursNum > 12) {
            console.error('Invalid hours: Hours must be between 1-12 in 12-hour format');
            return;
        }
    }

    // Format hours and minutes with leading zeros
    const formattedHours = hoursNum.toString().padStart(2, '0');
    const formattedMinutes = minutesNum.toString().padStart(2, '0');

    // Return formatted time string
    return `${dayjs().format('YYYY-MM-DD')} ${formattedHours}:${formattedMinutes} ${meridiem.toUpperCase()}`;
}
