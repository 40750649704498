import { routes } from "./routes";
import { UPLOADED_BY, Date_N_Time, VENDOR, INVOICE_NUMBER, ORDER, TOTAL } from './strings';
import { AiImageSize, CelebrationPages, ImageType } from "./staticTypes";

export const APP_NAME = "MicroLogx";
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "dev";
export const ORG_ID_HEADER = "X-Org-Id"; // api call header for tenant id
export const TENANT_ID_HEADER = "X-Tenant-Id"; // api call header for tenant id
export const TENANT_STAFF = "tenantStaff"; // key for tenant staff type
export const TENANT_ADMIN = "tenant"; // key for tenant admin type
export const ORGANIZATION_ADMIN = "organizationAdmin";
export const TENANT_NAME = "tenantName";
export const STAFF_ID = "staffId";
export const USER_NAME = "user";
export const ORGANIZATION_ADMIN_TYPE = "organizationAdmin"; // another key for tenant organization admin type
export const TENANT_ADMIN_TYPE = "tenantAdmin"; // another key for tenant admin type
export const MATRIX_COLUMN_DEFAULT_NAME = "firstColumn";
export const MATRIX_COLUMN_DEFAULT_CELL_TYPE = "radiogroup";
export const SPECIAL_EFFECTS_NAME = 'celebrationEffects'
export const TIMEZONE_INFO = 'timezoneInfo'
export const FIREBASE_DETAILS = 'firebaseDetails'

export const TIME_FORMAT = "h:mm A"
export const DATE_FORMAT = "MMM D, YYYY"
export const DATE_TIME_FORMAT = "MMM D, YYYY, h:mm A"

// api methods
export enum HttpMethods {
    GET = "get",
    POST = "post",
    PUT = "put",
    DELETE = "delete"
}

// alert variants
export enum AlertVariant {
    SUCCESS = "success",
    ERROR = "error"
}

// default roles
export const defaultRoles = [
    "ADMIN", "MANAGER", "HOST", "SERVER"
]

// user operations
export enum UserOperations {
    SELECT, ADD, EDIT, UPDATE, DELETE
}

// ui form class
export enum FormClass {
    VALID = "form-control",
    INVALID = "form-control is-invalid-custom"
}

export enum PhoneNumberFormClass {
    VALID = "form-control custom-country-phone",
    INVALID = "form-control is-invalid-custom custom-country-phone"
}

// menu item types
export enum ItemType {
    FOOD = "food",
    BEVERAGE = "beverage"
}

// menu price types
export enum PriceType {
    ITEM = "itemPrice",
    ADDON = "addon"
}

// menu price keys
export enum PriceKeys {
    ITEM = "itemPrices",
    ADDON = "addonPrices"
}

export enum WarningCallback { // enum for identifying delete menu type
    DEL_CATEGORY = "category",
    DEL_ITEM = "item",
    ACTIVATE_DEACTIVATE_MENU = "activate-deactivate-menu",
    ACTIVATE_DEACTIVATE_ITEM = "activate-deactivate-item"
}

export enum DragItemType {
    MENU_DRAG = "menu", CATEGORY_DRAG = "category", ITEM_DRAG = "item"
}

// file types
export enum FileType {
    IMAGE = "image",
    VIDEO = "video"
}

// permissions
export enum PermissionKeys {
    MANAGE_ROLES = "manage_roles",
    MANAGE_USERS = "manage_users",
    MANAGE_MENU = "manage_f_b_menu",
    MANAGE_GUEST_INFO = "manage_guest_info",
    MANAGE_SURVEY = "manage_survey",
    MANAGE_REPORTS = "manage_reports",
    MANAGE_GAME = "manage_game",
    MANAGE_COUPON = "manage_coupon",
    MANAGE_FILES = "manage_documents",
    MANAGE_WELCOME_SCREEN = "manage_branding_welcome_screen",
    MANAGE_ADDONS = "manage_addons",
    MANAGE_INGREDIENTS = "manage_key_ingredients",
    MANAGE_CUSTOM_FNB_MENU = "manage_custom_f_b_menu",
    MANAGE_NEWS_AND_EVENTS = "manage_posts",
    MANAGE_ADDON_GROUPS = "addon_groups",
    MANAGE_TIME_OFF = "manage_time_off",
    MANAGE_BRANDING = "branding",
    MANAGE_DASHBOARD = "dashboard",
    MANAGE_MY_TIME_OFF = "time_off_module",
    MANAGE_HOLIDAY = "holiday",
    MANAGE_AVAILABILITY = "manage_availability",
    MANAGE_SHIFT = "manage_shift",
    MANAGE_WORK_TIME_SCHEDULE = "manage_work_time_schedule",
    MANAGE_PERMISSIONS = "manage_permission_groups",
    MANAGE_GENERAL_SETTINGS = 'manage_general_settings',
    MANAGE_ABOUT_US = 'manage_branding_about_us',
    MANAGE_MY_SCHEDULE = 'my_schedule',
    MANAGE_SWAP_SHIFT = 'shift_swap',
}

// sub permission key
export enum SubPermissionKeys {
    ADD = "add",
    EDIT = "edit",
    DELETE = "delete",
    STATUS = "change_status",
    VALIDITY_CHECK = "validity_check",
    REDEEM = "redeem",
    DOCUMENT_DELETE = "document_delete",
    DOCUMENT_DOWNLOAD = "document_download",
    DOCUMENT_RENAME = "document_rename",
    DOCUMENT_UPLOAD = "document_upload",
    FOLDER_CREATION = "folder_creation",
    MANAGE_SCHEDULE = "manage_schedule",
    MANAGE_LOGOS = "manage_logos",
    MANAGE_REQUEST = "manage_requests",
    VIEW_SURVEY_COMPLETION_GRAPH = "survey_completion_graph",
    VIEW_SURVEY_FEEDBACK_GRAPH = "survey_feedback_graph",
    VIEW_FREQUENTLY_VISITED = "frequently_visited",
    VIEW_TIME_OFF_LIST = "time_off_list",
    VIEW_HOLIDAY_LIST = "holiday_list",
    MY_TIME_OFF = "my_time_off",
    VIEW_AVAILABILITY = "view_availability",
}

// survey question type enum
export enum SurveyQuestionType {
    STAR_RATING = "starRating",
    TRUE_FALSE = "trueOrFalse",
    TEXT_BOX = "strInputBox",
    TEXT_AREA = "textInputBox",
    MATRIX_TABLE = "matrixTable"
}

// survey questions type dropdown
export const SURVEY_QUESTION_TYPES = {
    [SurveyQuestionType.STAR_RATING]: "Star Rating",
    [SurveyQuestionType.TRUE_FALSE]: "Yes/No/NA",
    [SurveyQuestionType.TEXT_BOX]: "Text Box",
    [SurveyQuestionType.TEXT_AREA]: "Text Area",
    [SurveyQuestionType.MATRIX_TABLE]: "Matrix Table"
} as { [key: string]: string }

// survey category types
export const SURVEY_CATEGORY_TYPES = {
    greeting: "Greeting",
    service: "Service",
    food: "Food",
    ambiance: "Ambiance",
    cleanliness: "Cleanliness",
    overall: "Overall"
} as { [key: string]: string }

// Public Locations
export const GUEST_LOCATIONS = [
    routes.menu_view,
    routes.menu_item_view,
    routes.wine_history,
    routes.survey_guest,
    routes.welcome_page,
    routes.news_and_events,
    routes.news_in_detail,
    routes.news_only,
    routes.events_only,
    routes.events_in_detail,
    routes.about_us,
]
// Special menu locations
export const SPECIAL_MENU_LOCATIONS = [
    routes.special_menu, routes.item_view_special_menu
]

// rating star position values
export const STAR_RATING_POSITIONS = ['first', 'second', 'third', 'fourth', 'fifth'];

// game wheel
export const DEFAULT_BACKGROUND_COLORS = ['darkgrey', 'lightgrey'];
export const DEFAULT_TEXT_COLORS = ['black'];
export const DEFAULT_OUTER_BORDER_COLOR = 'black';
export const DEFAULT_OUTER_BORDER_WIDTH = 5;
export const DEFAULT_INNER_RADIUS = 0;
export const DEFAULT_INNER_BORDER_COLOR = 'black';
export const DEFAULT_INNER_BORDER_WIDTH = 0;
export const DEFAULT_RADIUS_LINE_COLOR = 'black';
export const DEFAULT_RADIUS_LINE_WIDTH = 5;
export const DEFAULT_FONT_SIZE = 15;
export const DEFAULT_TEXT_DISTANCE = 45;
export const DEFAULT_SPIN_DURATION = 1.0;

// file manager
export const ALLOWED_FILE_FORMATS = ["doc", "docm", "docx", "heic", "jpeg", "jpg", "ods", "odt", "pdf", "png", "tiff", "txt", "xls", "xlsm", "xlsx",]
export const ROOT_FOLDER = "root-folder"
export const INVOICE_FILTER_OPTIONS = [
    { title: UPLOADED_BY, display: true },
    { title: Date_N_Time, display: true },
    { title: VENDOR, display: true },
    { title: INVOICE_NUMBER, display: false },
    { title: ORDER, display: false },
    { title: TOTAL, display: false },
]

// menu schedule
export const MENU_RERENDER_INTERVAL = 30000

export enum ScheduleTypeValues {
    PERIODIC = 'periodic',
    SPECIFIC_DATE = 'specificDate ',
    ALWAYS = 'always',
    EMPTY = ""
}

// local storage keys
export const SELECTED_MENU = "selected-menu"
export const SPECIAL_MENU_CODE = "special-menu-code"
export const FONT_SIZE_COUNT = "font-size-count-value"
export const ADMIN_SELECTED_MENU = "admin-selected-menu"
export const ADMIN_SELECTED_CATEGORY = "admin-selected-category"

// toast message component
export const toastMessageInitialData = {
    message: "",
    variant: "",
    show: false,
}

export enum SpecialMenuIdPrefixes {
    SPECIAL_MENU = 'specialMenu#',
    SPECIAL_MENU_CATEGORY = 'specialMenuCatalog#',
    SPECIAL_MENU_ITEM = 'specialMenuCatalogItem#',
}

export enum PageMode {
    ADD = 'add',
    EDIT = 'edit',
}

export enum LOGIN_METHOD {
    POS = "POS",
    link = "Link",
    email = "Email",
}

export const IMAGE_TYPE: ImageType = {
    LANDSCAPE: { width: 600, height: 400, aspectRatio: 3 / 2 },
    PORTRAIT: { width: 600, height: 400, aspectRatio: 3 / 2 },
    BANNER: { width: 900, height: 300, aspectRatio: 3 / 1 },
    FREEFORM: { width: undefined, height: undefined, aspectRatio: undefined },
    MENU: { width: 600, height: 400, aspectRatio: 3 / 2 },
    BEVERAGE: { width: 600, height: 600, aspectRatio: 1 / 1 },
    FOOD: { width: 600, height: 400, aspectRatio: 3 / 2 },
    INGREDIENT: { width: 600, height: 400, aspectRatio: 3 / 2 },
    NEWS: { width: 600, height: 400, aspectRatio: 3 / 2 },
    EVENTS: { width: 600, height: 400, aspectRatio: 3 / 2 },
}

// Dalle-2 supported dimensions for AI
export const AI_IMAGE_SIZE_VALUES: { [key in AiImageSize]: number } = {
    [AiImageSize.SMALL]: 256,
    [AiImageSize.MEDIUM]: 512,
    [AiImageSize.LARGE]: 1024,
};

export const MOBILE_VIEW_BREAKPOINT = 670;
export const TAB_VIEW_BREAKPOINT = 992;

export const FESTIVAL_TEMPLATES = {
    VALENTINES_DAY: 'valentinesDay',
    ST_PATRICKS_DAY: 'stPatricksDay',
    EASTER: 'easter',
    MOTHERS_DAY: 'mothersDay',
    FATHERS_DAY: 'fathersDay',
    THANKS_GIVING: 'thanksgiving',
    CHRISTMAS_NEWYEAR: 'christmasNewYear',
    CINCO_DEMAYO: 'cincoDeMayo',
}

export const FESTIVAL_THEME_PAGE_CONFIG: Record<string, CelebrationPages> = {
    WELCOME_PAGE: 'welcomePage',
    ALL_OTHER_PAGES: 'allOtherPage'
}

export const DateFormats = [
    { example: "2021", format: "YYYY" },
    { example: "202108", format: "YYYYMM" },
    { example: "2021-08", format: "YYYY-MM" },
    { example: "8/5", format: "M/D" },
    { example: "08/05", format: "MM/DD" },
    { example: "20210805", format: "YYYYMMDD" },
    { example: "2021-08-05", format: "YYYY-MM-DD" },
    { example: "2021_08_05", format: "YYYY_MM_DD" },
    { example: "2021.08.05", format: "YYYY.MM.DD" },
    { example: "8/5/21", format: "M/D/YY" },
    { example: "08/05/21", format: "MM/DD/YY" },
    { example: "08/05/2021", format: "MM/DD/YYYY" },
    { example: "05 Aug 21", format: "DD MMM YY" },
    { example: "05 Aug 2021", format: "DD MMM YYYY" },
    { example: "05 August 2021", format: "DD MMMM YYYY" },
    { example: "Aug 5, 21", format: "MMM D, YY" },
    { example: "Aug 5, 2021", format: "MMM D, YYYY" },
    { example: "Aug 05, 2021", format: "MMM DD, YYYY" },
    { example: "August 5, 2021", format: "MMMM D, YYYY" },
    { example: "August 05, 2021", format: "MMMM DD, YYYY" },
    { example: "We Aug 5 21", format: "dd MMM D YY" },
    { example: "Wed Aug 5 21", format: "ddd MMM D YY" },
    { example: "Wed Aug 5 2021", format: "ddd MMM D YYYY" },
    { example: "Wed Aug 05 2021", format: "ddd MMM DD YYYY" },
    { example: "Wednesday, Aug 5 2021", format: "dddd, MMM D YYYY" },
    { example: "Wednesday, August 5, 2021", format: "dddd, MMMM D, YYYY" },
    { example: "Wednesday, August 05, 2021", format: "dddd, MMMM DD, YYYY" },
];

export const TimeFormats = [
    {
        example: "13:15",
        format: "HH:mm",
    },
    {
        example: "1:15 PM",
        format: "h:mm A",
    },
    {
        example: "01:15 PM",
        format: "hh:mm A",
    },
    {
        example: "@ 1:15 PM",
        format: "@ h:mm A",
    },
    {
        example: "13:15:04",
        format: "HH:mm:ss",
    },
    {
        example: "13:15.04",
        format: "HH:mm.ss",
    },
];

export const defaultFontScaleValue = 50;

export const defaultScheduleDataColor = {
    backgroundColor: '#87b6fb',
    color: '#262930'
} 