import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { ColumnHeaderOptions } from 'primereact/column';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { DayOfWeek, PerDayCount, ToastMessageProps } from '../../constants/staticTypes';
import { getFirstLetterUpperCase } from '../../helpers/utils';
import { getUserRoles } from '../../helpers/userRolesHelper';
import { initialScheduleData, CustomEvent, EmployeeScheduleData, generateWeekdayDates, Weekday, days, Shift, Staff, formatApiStaffData, ScheduleData, ScheduleMetadata, StaffRequirement, UpdateDraftMetaData, updateDraftMetadata, PublishedSchedule, JobRoleList, StyleDetails } from '../../helpers/workTimeScheduleHelper';
import { JTranslation } from '../../helpers/jTranslate/JTranslation';
import { Panel } from 'primereact/panel';
import { SliderSkeleton } from '../../helpers/menuViewHelper';
import { UPDATE } from '../../constants/strings';
import { usePrevNextButtons } from '../f&b_menu/guest/EmblaCarouselArrowButton';
import { useQueryClient } from 'react-query';
import { USER_ROLES } from '../../constants/queryKeys';
import { v4 as uuidv4 } from 'uuid';
import CustomEventForm from './CustomEventForm';
import dayjs from 'dayjs';
import useEmblaCarousel from 'embla-carousel-react';
import useMutationHook from '../../hooks/useMutationHook';
import useQueryHook from '../../hooks/useQueryHook';

type Props = {
    reloadPageData: () => void
    saveEmployeeName: (name: string) => void
    scheduleId: string
    selectedWeek: dayjs.Dayjs
    setData: Dispatch<SetStateAction<Staff | null>>,
    setSelectedDay: Dispatch<SetStateAction<string>>
    setShiftSideBar: React.Dispatch<React.SetStateAction<boolean>>
    setToastMessage: Dispatch<SetStateAction<ToastMessageProps>>
    shiftSideBar: boolean,
    staffingRequirement: PerDayCount[]
    staffsWithSchedule: ScheduleData | null
    scheduleMetadata: ScheduleMetadata | null
}

const ShiftSchedulerMobileView = ({
    reloadPageData,
    saveEmployeeName,
    scheduleId,
    selectedWeek,
    setData,
    setShiftSideBar,
    setToastMessage,
    shiftSideBar,
    staffingRequirement,
    staffsWithSchedule,
    setSelectedDay,
    scheduleMetadata,
}: Props) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({})
    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi)

    const [scheduleData, setScheduleData] = useState<EmployeeScheduleData[]>(initialScheduleData)
    const [weekdayDates, setWeekdayDates] = useState<Weekday[]>()
    const [confirmPopupInfo, setConfirmPopupInfo] = useState({ popUpName: '', visible: false, count: '', dayName: '' })
    const [rolesList, setRolesList] = useState<JobRoleList[] | []>(); // roles list


    const queryClient = useQueryClient()
    const scheduleMutation = useMutationHook(queryClient, true)
    const { staffs, schedule, holidays, mandatoryDays } = staffsWithSchedule!
    const { actionStatus } = schedule

    // roles list
    useQueryHook(USER_ROLES, getUserRoles, (res) => setRolesList(res.data?.data.lists));

    const getRoleColorCode = (roleId: string): StyleDetails => {
        const role = rolesList?.find(role => role.id === roleId)

        if (role?.styleDetails) {
            return role.styleDetails
        } else {
            return {
                backgroundColor: '#87b6fb',
                color: '#262930'
            }
        }
    }

    useEffect(() => {
        if (staffsWithSchedule) {
            let staffsData = formatApiStaffData({ scheduleData: staffsWithSchedule })
            setScheduleData(staffsData)
        }
    }, [staffsWithSchedule])

    useEffect(() => {
        if (selectedWeek) {
            const dates = generateWeekdayDates(selectedWeek)
            setWeekdayDates(dates)
        }
    }, [selectedWeek])

    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        reloadPageData()
    }

    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const updateMinEmployeeCount = () => {
        let staffRequirement = scheduleMetadata?.staffRequirement ?? [] as StaffRequirement[]
        let updatedStaffRequirement = staffRequirement.map((reqData): StaffRequirement => {
            if (reqData.dayName.toLowerCase() === confirmPopupInfo.dayName.toLowerCase()) {
                return { ...reqData, customCount: Number(confirmPopupInfo.count) }
            } else {
                return reqData
            }
        })

        let customEvents = scheduleMetadata?.customEvents ?? [] as CustomEvent[]
        let updatedCustomEvents = customEvents?.map((event): CustomEvent => {
            return event
        })

        const params: UpdateDraftMetaData = {
            scheduleId,
            scheduleMetadata: {
                staffRequirement: updatedStaffRequirement,
                customEvents: updatedCustomEvents,
            },
        }
        // update API call
        updateDraftMetadata(scheduleMutation, params, onSuccess, onError)
    }

    const headerTemplate = (data: Weekday) => {
        const { day } = data
        const customEvent = scheduleMetadata?.customEvents?.find((event) => event.dayName?.toLowerCase() === day?.toLowerCase())

        return (
            <CustomEventForm
                parent='mobile'
                scheduleId={scheduleId}
                scheduleMetadata={scheduleMetadata}
                customEvent={customEvent ?? {} as CustomEvent}
                scheduleMutation={scheduleMutation}
                onSuccess={onSuccess}
                onError={onError}
                day={day as DayOfWeek}
                selectedWeek={selectedWeek}
                actionStatus={actionStatus}
                staffingRequirement={staffingRequirement}
                headerData={staffs as unknown as ColumnHeaderOptions}
                holidays={holidays}
                mandatoryDays={mandatoryDays}
            />
        )
    }

    const getFormattedDate = (day: DayOfWeek) => {
        let date = ''
        weekdayDates?.forEach((weekDay) => {
            if (weekDay.day === day) {
                date = dayjs(weekDay.date).format('ddd, D MMM YYYY')
            }
        })
        return date
    }

    const cellBodyTemplate = ({ staffName, shift, day, staffData }: { staffName: string; shift: Shift[], day: string; staffData: Staff }) => {
        if (shift.length) {
            return shift.map((shift) => {
                const schedule = (shift as any) as PublishedSchedule
                const assignedShifts = schedule.assignedShifts
                const { startTime, endTime, isBd, isClose, role, roleId } = assignedShifts;
                const styleDetails = getRoleColorCode(roleId);
                const { backgroundColor, color } = styleDetails
                const uuid = uuidv4()
                return (
                    <div
                        key={uuid}
                        className='scheduler-shift-card-body'
                        style={{
                            backgroundColor: "white",
                            border: `2px solid ${backgroundColor}`,
                        }}
                        onClick={() => {
                            if (actionStatus === 'draft') {
                                setShiftSideBar(!shiftSideBar)
                                saveEmployeeName(staffName)
                                setSelectedDay(day)
                                setData(staffData)
                            }
                        }}
                    >
                        <div className="d-flex">
                            <div className="flex-grow-0 d-flex align-items-center justify-center">
                                <span
                                    className="shift-role"
                                    style={{
                                        backgroundColor,
                                        color: color
                                    }}
                                >{getFirstLetterUpperCase(role)}</span>
                            </div>
                            <div className="flex-grow-1 shift-name-main text-nowrap">
                                {isClose ? `${startTime} - Close` : ""}
                                {isBd ? `${startTime} - V` : ""}
                                {!isBd && !isClose ? `${startTime} - ${endTime}` : ""}
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            if (actionStatus === 'draft') {
                return (
                    <div
                        className="scheduler-cell-body"
                        onClick={() => {
                            setShiftSideBar(!shiftSideBar)
                            saveEmployeeName(staffName)
                            setSelectedDay(day)
                            setData(staffData)
                        }}
                    >
                        <i className="ri-add-line"></i>
                    </div>
                )
            } else {
                return <JTranslation text={"No Schedule"} />
            }

        }
    }

    return (
        <div className="card">
            <ConfirmPopup
                target={document.getElementById(confirmPopupInfo.popUpName) ?? undefined}
                visible={confirmPopupInfo.visible}
                onHide={() =>
                    setConfirmPopupInfo((prev) => {
                        return {
                            ...prev,
                            visible: false,
                        }
                    })
                }
                message={() => {
                    return (
                        <div>
                            <p><JTranslation text={"Minimum Employee"} /></p>
                            <input
                                type="text"
                                className="form-control"
                                value={confirmPopupInfo.count}
                                onChange={(e) =>
                                    setConfirmPopupInfo((prevState) => ({ ...prevState, count: e.target.value }))
                                }
                            />
                        </div>
                    )
                }}
                acceptLabel={UPDATE}
                accept={() => {
                    updateMinEmployeeCount()
                }}
                data-testid="confirm-popup"
            />

            <div className='col-lg-12 my-3'>
                <div className='scroll-menu-container'>
                    <div className='nav-scroller'>
                        <nav className='nav-scroller-nav'>
                            <div className='nav-scroller-content-new main-menu'>
                                {weekdayDates?.length ? (
                                    <div className="embla">
                                        <div className="embla__viewport" ref={emblaRef}>
                                            <div className="embla__container">
                                                {weekdayDates?.map((data, index) => {
                                                    return (
                                                        <div className="embla__slide" key={index}>
                                                            {headerTemplate(data)}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <SliderSkeleton />
                                )}
                            </div>
                        </nav>
                    </div>
                </div>
                <div className='mt-2 d-flex justify-content-end align-items-center'>
                    <Button
                        size="small"
                        icon="ri-arrow-left-s-line"
                        rounded outlined
                        aria-label="Cancel"
                        onClick={onPrevButtonClick}
                        disabled={prevBtnDisabled}
                        style={{ width: '2rem', height: '2rem', padding: 0 }}
                    />
                    <Button
                        className='ms-2'
                        size="small"
                        icon="ri-arrow-right-s-line"
                        rounded outlined
                        aria-label="Cancel"
                        onClick={onNextButtonClick}
                        disabled={nextBtnDisabled}
                        style={{ width: '2rem', height: '2rem', padding: 0 }}
                    />
                </div>
            </div>

            <Accordion>
                {scheduleData.map((schedule: EmployeeScheduleData) => (
                    <AccordionTab header={<JTranslation text={schedule.staffName} />} key={uuidv4()}>
                        {days.map((day) => {
                            return (
                                <Panel key={uuidv4()} header={getFormattedDate(day)} toggleable collapsed>
                                    <div className='row'>
                                        <div className='col'>
                                            {cellBodyTemplate({
                                                staffName: schedule.staffName,
                                                shift: schedule[day],
                                                day: day,
                                                staffData: schedule.staffData
                                            })}
                                        </div>
                                        {schedule[day].length && actionStatus === 'draft'
                                            ? (
                                                <div
                                                    className="scheduler-cell-body col-1"
                                                    onClick={() => {
                                                        setShiftSideBar(!shiftSideBar)
                                                        saveEmployeeName(schedule.staffName)
                                                        setSelectedDay(day)
                                                        setData(schedule.staffData)
                                                    }}
                                                >
                                                    <i className="ri-add-line"></i>
                                                </div>
                                            )
                                            : null}
                                    </div>
                                </Panel>
                            )
                        })}
                    </AccordionTab>
                ))}
            </Accordion>
        </div>
    )
}

export default ShiftSchedulerMobileView

