import { Dispatch, SetStateAction, useState } from 'react'
import { Column } from 'primereact/column'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { DataTable } from 'primereact/datatable'
import { days, ScheduleData, StaffRequirement, updateDraftMetadata, UpdateDraftMetaData, Staff, getDateOfWeekday, ScheduleMetadata, CustomEvent, JobRole, isDateInRange, JobRoleList, StyleDetails, getTimeOffMessage, isDateInSelectedWeek } from '../../helpers/workTimeScheduleHelper'
import { getFirstLetterUpperCase } from '../../helpers/utils'
import { GetStaffAvailability } from './ShiftScheduleSideBar'
import { getUserRoles } from '../../helpers/userRolesHelper'
import { JTranslation } from '../../helpers/jTranslate/JTranslation'
import { NO_ROLES_ASSIGNED, UPDATE } from '../../constants/strings'
import { PerDayCount, ToastMessageProps } from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import { USER_ROLES } from '../../constants/queryKeys'
import CustomEventForm from './CustomEventForm'
import CustomTooltip from './CustomTooltip'
import dayjs from 'dayjs'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'

type Props = {
    reloadPageData: () => void
    saveEmployeeName: (name: string) => void
    scheduleId: string
    selectedWeek: dayjs.Dayjs
    setData: Dispatch<SetStateAction<Staff | null>>,
    setSelectedDay: Dispatch<SetStateAction<string>>
    setShiftSideBar: React.Dispatch<React.SetStateAction<boolean>>
    setToastMessage: Dispatch<SetStateAction<ToastMessageProps>>
    shiftSideBar: boolean,
    staffingRequirement: PerDayCount[]
    staffsWithSchedule: ScheduleData | null
    scheduleMetadata: ScheduleMetadata | null
}

const ShiftScheduler = ({
    reloadPageData,
    saveEmployeeName,
    scheduleId,
    selectedWeek,
    setData,
    setShiftSideBar,
    setToastMessage,
    shiftSideBar,
    staffingRequirement,
    staffsWithSchedule,
    setSelectedDay,
    scheduleMetadata
}: Props) => {
    const [confirmPopupInfo, setConfirmPopupInfo] = useState({ popUpName: '', visible: false, count: '', dayName: '' })
    const [rolesList, setRolesList] = useState<JobRoleList[] | []>(); // roles list
    const queryClient = useQueryClient()
    const scheduleMutation = useMutationHook(queryClient, true)
    const { staffs, schedule, holidays, mandatoryDays } = staffsWithSchedule!

    // roles list
    useQueryHook(USER_ROLES, getUserRoles, (res) => setRolesList(res.data?.data.lists));

    const getRoleColorCode = (roleId: string): StyleDetails => {
        const role = rolesList?.find(role => role.id === roleId)

        if (role?.styleDetails) {
            return role.styleDetails
        } else {
            return {
                backgroundColor: '#87b6fb',
                color: '#262930'
            }
        }
    }

    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        reloadPageData()
    }

    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const updateMinEmployeeCount = () => {
        let staffRequirement = scheduleMetadata?.staffRequirement ?? [] as StaffRequirement[]
        let updatedStaffRequirement = staffRequirement.map((reqData): StaffRequirement => {
            if (reqData.dayName.toLowerCase() === confirmPopupInfo.dayName.toLowerCase()) {
                return { ...reqData, customCount: Number(confirmPopupInfo.count) }
            } else {
                return reqData
            }
        })

        let customEvents = scheduleMetadata?.customEvents ?? [] as CustomEvent[]
        let updatedCustomEvents = customEvents?.map((event): CustomEvent => {
            return event
        })

        const params: UpdateDraftMetaData = {
            scheduleId,
            scheduleMetadata: {
                staffRequirement: updatedStaffRequirement,
                customEvents: updatedCustomEvents,
            },
        }
        // update API call
        updateDraftMetadata(scheduleMutation, params, onSuccess, onError)
    }

    const staffNameTemplate = (rowData: Staff) => {
        const { preferredName = '', jobRoles = [] } = rowData ?? {}
        const roles = jobRoles
            .map((role: JobRole | undefined) => role?.jobRole?.jobRoleName)
            .filter(Boolean)
            .join(', ')

        return (
            <div className="text-nowrap">
                <div className="overflow-ellipsis fw-bold">{<JTranslation typeCase="pascal" text={preferredName} />}</div>
                <div className="overflow-ellipsis" title={roles}>
                    <JTranslation typeCase="pascal" text={roles || NO_ROLES_ASSIGNED} />
                </div>
            </div>
        )
    }

    const getStaffAvailabilityForTheDay = ({ day, availabilities }: GetStaffAvailability) => {
        // Check if availabilities exist for the specified day
        if (!availabilities || Object.keys(availabilities).length === 0) {
            return <></>
        }

        const staffAvailabilities: any = availabilities[day]?.filter((availability) => {
            const { weekday, startDate, endDate } = availability;
            const isDateCorrect = isDateInSelectedWeek({ startDate, endDate, selectedWeek });

            // return isAvailable && day === weekday && isDateCorrect;
            return day === weekday && (isDateCorrect || availability.isRecurring === true) && (availability.actionStatus === 'APPROVED' || availability.actionStatus === 'PENDING');
        })

        let sortedAvailabilities: any[] = [];
        if (staffAvailabilities) {
            const hasNonRecurring = staffAvailabilities.some((availability: any) => !availability.isRecurring);

            // if there is temporary availability, use it
            const filteredAvailabilities = hasNonRecurring
                ? staffAvailabilities.filter((availability: any) => !availability.isRecurring)
                : staffAvailabilities.filter((availability: any) => availability.isRecurring);

            // Now sort the filtered list by `createdAt` in descending order
            sortedAvailabilities = filteredAvailabilities.sort(
                (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
        }
        const staffAvailability = sortedAvailabilities.slice(0, 1);

        if (!staffAvailability[0]?.isAvailable) {
            return <strong>Not Available</strong>;
        }
    };

    const cellBodyTemplate = ({ day, rowData }: { day: string; rowData: Staff }) => {
        const { shifts, preferredName, unavailableDays, availabilities } = rowData
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const availabilityText = getStaffAvailabilityForTheDay({ day, availabilities })

        const unavailabilityArray = unavailableDays.filter((unavailableDay) => isDateInRange({ date: date, startDate: unavailableDay.startDate, endDate: unavailableDay.endDate }))
        const unavailability = unavailabilityArray.filter((item) => (item.actionStatus === 'APPROVED' || item.actionStatus === 'PENDING'))
        const shiftsArray = shifts[date]
        const isBusinessOpen = holidays.filter((holiday) => {
            const isSameDate = isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate })
            const isNotOpenForBusiness = holiday.isOpenForBusiness === false
            return isSameDate && isNotOpenForBusiness
        }).length > 0

        if (schedule.actionStatus === 'draft' && !Object.keys(shifts).length && !isBusinessOpen) {
            return (
                <>
                    {getTimeOffMessage({ unavailability: unavailability }, date)?.length
                        ? getTimeOffMessage({ unavailability: unavailability }, date)
                        : availabilityText}
                    <div
                        className={`small-add-button`}
                        onClick={() => {
                            setShiftSideBar(!shiftSideBar)
                            saveEmployeeName(rowData.preferredName)
                            setSelectedDay(day)
                            setData(rowData)
                        }}
                    >
                        <i className="ri-add-line"></i>
                    </div>
                </>
            )
        }

        if (shiftsArray?.length) {
            // Sort the shiftsArray based on startTime and endTime 
            const sortedShifts = shiftsArray.sort((a, b) => {
                const startA = dayjs(a.assignedShifts.startTime, "hh:mm A");
                const startB = dayjs(b.assignedShifts.startTime, "hh:mm A");
                const endA = dayjs(a.assignedShifts.endTime, "hh:mm A");
                const endB = dayjs(b.assignedShifts.endTime, "hh:mm A");

                // Compare start times first; if they're equal, compare end times
                if (startA.isSame(startB)) {
                    return endA.isBefore(endB) ? -1 : 1;
                }
                return startA.isBefore(startB) ? -1 : 1;
            });

            return sortedShifts.map((shift, index) => {
                const { assignedShifts } = shift;
                const { startTime, endTime, isBd, isClose, role, roleId } = assignedShifts;
                const styleDetails = getRoleColorCode(roleId);
                const { backgroundColor, color } = styleDetails;

                return (
                    <>
                        <CustomTooltip key={index} staffName={preferredName} showDelay={500} content={sortedShifts}>
                            <>
                                {index === 0
                                    ? getTimeOffMessage({ unavailability: unavailability }, date)?.length
                                        ? getTimeOffMessage({ unavailability: unavailability }, date)
                                        : availabilityText
                                    : ""}
                                <div
                                    className="scheduler-shift-card-body"
                                    style={{
                                        backgroundColor: "white",
                                        border: `2px solid ${backgroundColor}`,
                                    }}
                                    onClick={() => {
                                        if (schedule.actionStatus === "draft") {
                                            setShiftSideBar(!shiftSideBar);
                                            saveEmployeeName(rowData.preferredName);
                                            setSelectedDay(day);
                                            setData(rowData);
                                        }
                                    }}
                                >
                                    <div className="d-flex">
                                        <div className="flex-grow-0 d-flex align-items-center justify-center">
                                            <span
                                                className="shift-role"
                                                style={{
                                                    backgroundColor,
                                                    color: color
                                                }}
                                            >{getFirstLetterUpperCase(role)}</span>
                                        </div>
                                        <div className="flex-grow-1 shift-name-main text-nowrap">
                                            {isClose ? `${startTime} - Close` : ""}
                                            {isBd ? `${startTime} - V` : ""}
                                            {!isBd && !isClose ? `${startTime} - ${endTime}` : ""}
                                        </div>
                                    </div>
                                </div>
                            </>
                        </CustomTooltip>

                        {index === sortedShifts.length - 1 &&
                            schedule.actionStatus === "draft" &&
                            !isBusinessOpen ? (
                            <div
                                className={`small-add-button`}
                                onClick={() => {
                                    setShiftSideBar(!shiftSideBar);
                                    saveEmployeeName(rowData.preferredName);
                                    setSelectedDay(day);
                                    setData(rowData);
                                }}
                            >
                                <i className="ri-add-line"></i>
                            </div>
                        ) : null}
                    </>
                );
            });
        } else {
            return schedule.actionStatus === 'draft' && !isBusinessOpen ? (
                <div className='d-flex'>
                    <span
                        className={`${unavailability ? 'unavailable' : ''}`}
                    >
                        {getTimeOffMessage({ unavailability: unavailability }, date)?.length
                            ? getTimeOffMessage({ unavailability: unavailability }, date)
                            : availabilityText}
                    </span>
                    <div
                        className={`scheduler-cell-body`}
                        onClick={() => {
                            setShiftSideBar(!shiftSideBar)
                            saveEmployeeName(rowData.preferredName)
                            setSelectedDay(day)
                            setData(rowData)
                        }}
                    >
                        <i className="ri-add-line"></i>
                    </div>
                </div>
            ) : (
                ''
            )
        }
    }


    const renderColumns = () => {
        return days.map((day, index) => {
            const customEvent = scheduleMetadata?.customEvents?.find((event) => event.dayName.toLowerCase() === day.toLowerCase())
            return (
                <Column
                    key={index}
                    headerClassName="workTimeScheduleHeader"
                    header={(headerData) => (
                        <CustomEventForm
                            parent='web'
                            scheduleId={scheduleId}
                            scheduleMetadata={scheduleMetadata}
                            customEvent={customEvent ?? {} as CustomEvent}
                            scheduleMutation={scheduleMutation}
                            onSuccess={onSuccess}
                            onError={onError}
                            day={day}
                            selectedWeek={selectedWeek}
                            actionStatus={schedule.actionStatus}
                            staffingRequirement={staffingRequirement}
                            headerData={headerData}
                            holidays={holidays}
                            mandatoryDays={mandatoryDays}
                        />
                    )}
                    body={(data) => cellBodyTemplate({ day, rowData: data })}
                    style={{ width: '12.5%' }}
                    className="shift-column"
                />
            )
        })
    }

    return (
        <div className="card h-100">
            <ConfirmPopup
                target={document.getElementById(confirmPopupInfo.popUpName) ?? undefined}
                visible={confirmPopupInfo.visible}
                onHide={() =>
                    setConfirmPopupInfo((prev) => {
                        return {
                            ...prev,
                            count: '',
                            dayName: '',
                            visible: false,
                        }
                    })
                }
                message={() => {
                    return (
                        <div>
                            <p><JTranslation typeCase="pascal" text={'Minimum Employee'} /></p>
                            <input
                                type="text"
                                className="form-control"
                                value={confirmPopupInfo.count}
                                onChange={(e) =>
                                    setConfirmPopupInfo((prevState) => ({ ...prevState, count: e.target.value }))
                                }
                            />
                        </div>
                    )
                }}
                acceptLabel={UPDATE}
                accept={() => {
                    updateMinEmployeeCount()
                }}
                data-testid="confirm-popup"
            />
            <DataTable
                value={staffs}
                showGridlines
                scrollable
                //scrollHeight="75%"
                scrollHeight='100%'
                className="work-time-schedule-table d-flex h-100 flex-column"
            >
                <Column
                    field="day"
                    className="overflow-ellipsis"
                    header={<JTranslation text={"Events"} />}
                    body={staffNameTemplate}
                    style={{ maxWidth: '12rem' }}
                />
                {renderColumns()}
            </DataTable>
        </div>
    )
}

export default ShiftScheduler
